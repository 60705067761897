import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface UrlSelectorProperties {
  url: string;
}
export const UrlSelector: React.FC<UrlSelectorProperties> = ({ url }: UrlSelectorProperties) => {
  const [copied, setCopied] = useState(false);

  const selectUrl = async () => {
    const input = document.getElementById(url) as HTMLInputElement;
    if (input) {
      // Most browsers support copying, but if they don't, lets make things
      // easier by at least selecting the text
      input.focus();
      input.select();

      // Notify the user that the text is now in the clipboard
      await navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }
  };

  return (
    <div className="d-flex">
      {copied && (
        <div className="position-absolute px-1 bg-success opacity-75">Copied to clipboard</div>
      )}
      <input type="text" id={url} value={url} readOnly className="flex-grow-1 bg-birch" />
      <Button onClick={selectUrl}>
        <FontAwesomeIcon icon={faCopy} />
      </Button>
    </div>
  );
};

import * as turf from '@turf/turf';
import {
  bbox,
  bboxPolygon,
  booleanDisjoint,
  difference,
  Feature,
  intersect,
  MultiPolygon,
  Polygon,
  Properties,
} from '@turf/turf';
import { APISegmentFeature } from '../../models/Segments';

/**
 * Check if features have overlap but NOT share boundary.
 */
export function isFeaturesOverlap(
  feature1: Feature<Polygon | MultiPolygon, Properties>,
  feature2: Feature<Polygon | MultiPolygon, Properties>,
): boolean {
  try {
    const difference = intersect(feature1, feature2);
    if (difference) {
      return true;
    }
  } catch (e) {
    console.error('Unexpected error occurred when check features overlap', e, feature1, feature2);
  }
  return false;
}

/**
 * Check if features have overlap or share boundary.
 */
export function isFeatureWithinBoundary(
  feature: Feature<Polygon | MultiPolygon, Properties>,
  boundary: Feature<Polygon | MultiPolygon, Properties>,
): boolean {
  try {
    const box = bboxPolygon(bbox(boundary));
    const diff = difference(box, boundary);
    if (diff) {
      return booleanDisjoint(diff, feature);
    }
  } catch (e) {
    console.error(
      'Unexpected error occurred when check feature within customer boundary',
      e,
      feature,
    );
  }
  return false;
}

/**
 * Check if segmentation feature has valid geometry.
 */
export function isFeatureGeometryValid(f: APISegmentFeature): boolean {
  if (!f.geometry) {
    return false;
  }
  try {
    if (f.geometry.type === 'Polygon') {
      turf.polygon(f.geometry.coordinates);
      return true;
    }
    if (f.geometry.type === 'MultiPolygon') {
      turf.multiPolygon(f.geometry.coordinates);
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps } from 'react-bootstrap';

import styles from './IconButton.module.scss';
import styles2 from './NextButton.module.scss';

interface IconButtonProps extends ButtonProps {
  icon: IconProp;
  rightIcon?: IconProp;
  variant?: string;
}

/**
 * Button with an icon on it!
 */
export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  rightIcon,
  children,
  className,
  ...rest
}) => {
  return (
    <Button {...rest} className={`${styles.iconButton} ${styles2.greyDisable} ${className ?? ''}`}>
      <FontAwesomeIcon icon={icon} size="lg" /> {children}{' '}
      {rightIcon === undefined ? <div /> : <FontAwesomeIcon icon={rightIcon} size="lg" />}
    </Button>
  );
};

// Functions to help implement undo and redo

export interface UndoHistory<T> {
  stack: T[];
  index: number;
  lastChange?: {
    timestamp: number;
  };
  maxSize: number;
}

export function getValue<T>(history: UndoHistory<T>): T {
  return history.stack[history.index];
}

export function makeHistory<T>(value: T, { maxSize = 1000 } = {}): UndoHistory<T> {
  return {
    stack: [value],
    index: 0,
    maxSize,
  };
}

function setValueNoHistory<T>(history: UndoHistory<T>, value: T): UndoHistory<T> {
  return {
    ...history,
    stack: [...history.stack.slice(0, history.index), value],
  };
}

export function setValue<T>(history: UndoHistory<T>, value: T): UndoHistory<T> {
  const sliceStart = history.stack.length >= history.maxSize ? 1 : 0;
  const newStack = [...history.stack.slice(sliceStart, history.index + 1), value];
  return {
    ...history,
    stack: newStack,
    index: newStack.length - 1,
  };
}

export const DEBOUNCE_MS = 1000;

export function setValueDebounced<T>(
  history: UndoHistory<T>,
  updater: T | ((old: T) => T),
  opts?: { timestamp?: number },
): UndoHistory<T> {
  // WARNING: if T is a function, this will result in T being invoked rather than set as the value
  // Fixing this is probably not worth it currently as I can't think of a case where you want history
  // on a function
  // @ts-ignore
  const value = typeof updater === 'function' ? updater(getValue(history)) : updater;

  const timestamp = opts?.timestamp ?? Date.now();
  if (history.lastChange && timestamp - history.lastChange.timestamp < DEBOUNCE_MS) {
    return { ...setValueNoHistory(history, value), lastChange: { timestamp } };
  }
  return { ...setValue(history, value), lastChange: { timestamp } };
}

export function canUndo<T>(history: UndoHistory<T>): boolean {
  return history.index > 0;
}
export function canRedo<T>(history: UndoHistory<T>): boolean {
  return history.index < history.stack.length - 1;
}
export function undo<T>(history: UndoHistory<T>): UndoHistory<T> {
  return {
    ...history,
    index: canUndo(history) ? history.index - 1 : history.index,
  };
}
export function redo<T>(history: UndoHistory<T>): UndoHistory<T> {
  return {
    ...history,
    index: canRedo(history) ? history.index + 1 : history.index,
  };
}

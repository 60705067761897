import { faAngleDown, faAngleUp, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Dimensions, Measures, SiteSummary } from '../apis/generated';
import { useCompaniesCustomersV2 } from '../contexts/remote-data/useCompaniesCustomersV2';
import { useCurrentCompanyId } from '../contexts/remote-data/useCurrentCompanyId';
import { formatNum } from '../formatters/formatters';
import { isEtsScheme } from '../models/ets_util';
import {
  cc_charcoal,
  cc_lime,
  ccu_aquamarine,
  ccu_rose,
  lime_85,
  nzu_blue,
  nzu_orange,
} from '../theme/colors';
import BagelChart, { BagelTopping } from './BagelChart';
import { calculateForestArea } from './CustomerEntryV2';
import styles from './SitesDashboard.module.scss';
import { useUserMe } from '../contexts/remote-data/useUserMe';
import ChocolateBar, { BarTopping } from '../customer/financials/ChocolateBar';
import { useCCUPrice, useNZUPrice } from './NZUCustomPrice';

export function getDimensionKeys(
  dimensions: Record<string, Dimensions>,
  filter: (d: Dimensions) => boolean,
): string[] {
  return Object.keys(dimensions).filter((key) => filter(dimensions[key]));
}

interface BarAreaCategory {
  key: string;
  topping: BarTopping;
}

const FOREST_TYPES_FOR_CHART = {
  base_data: [
    {
      key: 'native_ets',
      scheme: 'ets',
      displayName: 'Native ETS',
      filter: (d: Dimensions) =>
        d.forest_type === 'native' &&
        d.report_category !== 'future_native_planting' &&
        isEtsScheme(d.credit_scheme),
      color: nzu_blue,
    },
    {
      key: 'native_ccu',
      scheme: 'ccu',
      displayName: 'Native',
      filter: (d: Dimensions) =>
        d.forest_type === 'native' &&
        d.report_category !== 'future_native_planting' &&
        d.credit_scheme === 'alternative_scheme',
      color: ccu_aquamarine,
    },
    {
      key: 'exotic_ets',
      scheme: 'ets',
      displayName: 'Exotic ETS',
      filter: (d: Dimensions) =>
        d.forest_type === 'exotic' &&
        d.report_category !== 'future_exotic_planting' &&
        isEtsScheme(d.credit_scheme),
      color: nzu_orange,
    },
    {
      key: 'exotic_ccu',
      scheme: 'ccu',
      displayName: 'Exotic',
      filter: (d: Dimensions) =>
        d.forest_type === 'exotic' &&
        d.report_category !== 'future_exotic_planting' &&
        d.credit_scheme === 'alternative_scheme',
      color: ccu_rose,
    },
    {
      key: 'future_native_ets',
      scheme: 'ets',
      displayName: 'Native ETS',
      filter: (d: Dimensions) =>
        d.report_category === 'future_native_planting' && isEtsScheme(d.credit_scheme),
      color: nzu_blue,
    },
    {
      key: 'future_native_ccu',
      scheme: 'ccu',
      displayName: 'Native',
      filter: (d: Dimensions) =>
        d.report_category === 'future_native_planting' && d.credit_scheme === 'alternative_scheme',
      color: ccu_aquamarine,
    },
    {
      key: 'future_exotic_ets',
      scheme: 'ets',
      displayName: 'Exotic ETS',
      filter: (d: Dimensions) =>
        d.report_category === 'future_exotic_planting' && isEtsScheme(d.credit_scheme),
      color: nzu_orange,
    },
    {
      key: 'future_exotic_ccu',
      scheme: 'ccu',
      displayName: 'Exotic',
      filter: (d: Dimensions) =>
        d.report_category === 'future_exotic_planting' && d.credit_scheme === 'alternative_scheme',
      color: ccu_rose,
    },
    {
      key: 'ineligible',
      scheme: undefined,
      displayName: 'Ineligible',
      filter: (d: Dimensions) => d.report_category === 'pre_1990_forest',
      color: cc_charcoal,
    },
  ],
  compound_data: [
    {
      key: 'future_native',
      displayName: 'Native',
      aggregate: ['future_native_ets', 'future_native_ccu'],
      color: nzu_blue,
    },
    {
      key: 'future_exotic',
      displayName: 'Exotic',
      aggregate: ['future_exotic_ets', 'future_exotic_ccu'],
      color: nzu_orange,
    },
    {
      key: 'future_forest_total',
      displayName: 'Future',
      aggregate: [
        'future_exotic_ets',
        'future_exotic_ccu',
        'future_native_ets',
        'future_native_ccu',
      ],
      color: lime_85,
    },
    {
      key: 'current_forest_total',
      displayName: 'Current',
      aggregate: ['native_ets', 'native_ccu', 'exotic_ets', 'exotic_ccu'],
      color: cc_lime,
    },
  ],
};

const CarbonIntelligencePanel: React.FC<{
  sites: SiteSummary[];
  showtCO2: boolean;
  dimensions?: Record<string, Dimensions>;
}> = ({ sites, showtCO2, dimensions = {} }) => {
  const companyId = useCurrentCompanyId();
  const { customers } = useCompaniesCustomersV2(companyId);

  const { userMe } = useUserMe();

  const { nzuPrice } = useNZUPrice();
  const { ccuPrice } = useCCUPrice();

  const forestAreaBase = FOREST_TYPES_FOR_CHART.base_data
    .map((forestType) => {
      const dimensionKeys = getDimensionKeys(dimensions, (d) => forestType.filter(d));
      const allCategoryMeasure = sites.flatMap((site) =>
        dimensionKeys.map((key) => site.measures[key]).filter((m) => m !== undefined),
      );
      return allCategoryMeasure.length !== 0
        ? {
            key: forestType.key,
            topping: {
              data: [_.sumBy(allCategoryMeasure, (m) => m.aea)],
              color: forestType.color,
              label: forestType.displayName,
            },
          }
        : undefined;
    })
    .filter((e) => e !== undefined) as BarAreaCategory[];

  const forestArea = forestAreaBase.concat(
    FOREST_TYPES_FOR_CHART.compound_data.map((agg) => {
      const constituent_sum = _.sumBy(
        forestAreaBase.filter((v) => agg.aggregate.includes(v.key)),
        (val) => val.topping.data[0],
      );
      return {
        key: agg.key,
        topping: { color: agg.color, label: agg.displayName, data: [constituent_sum] },
      };
    }),
  );

  const getBagelToppings = (dataSource: BarAreaCategory[], keys: string[]): BagelTopping[] => {
    return dataSource
      .filter((data) => keys.includes(data.key))
      .map((data) => {
        return { color: data.topping.color, label: data.topping.label, data: data.topping.data[0] };
      });
  };

  const getBarToppings = (dataSource: BarAreaCategory[], keys: string[]): BarTopping[] => {
    return dataSource.filter((data) => keys.includes(data.key)).map((data) => data.topping);
  };

  const totalSitesArea = sites.reduce((sum, site) => sum + (site.site_area_m2 ?? 0) / 1e4, 0);

  const totalForestArea = sites.reduce(
    (sum, site) => sum + calculateForestArea(site, Object.keys(dimensions)),
    0,
  );

  const [showCharts, setShowCharts] = useState(false);

  const sequestrationYearByYearBase = FOREST_TYPES_FOR_CHART.base_data
    .map((forestType) => {
      const dimensionKeys = getDimensionKeys(dimensions, (d) => forestType.filter(d));
      const allCategoryMeasure = sites.flatMap((site) =>
        dimensionKeys.map((key) => site.measures[key]).filter((m) => m !== undefined),
      );

      const getYearlyCategoryMeasure = (measures: Measures[]) =>
        _.range(measures[0].seq.length).map((v, i) =>
          _.sumBy(measures, (m) => {
            if (showtCO2) return m.seq[i];
            if (forestType.scheme === 'ets') {
              return (
                m.seq[i] *
                (nzuPrice && customers
                  ? nzuPrice.values[customers.start_year + 1 + i - nzuPrice.start_year]
                  : 0)
              );
            }
            if (forestType.scheme === 'ccu') {
              return (
                m.seq[i] *
                (ccuPrice && customers
                  ? ccuPrice.values[customers.start_year + 1 + i - ccuPrice.start_year]
                  : 0)
              );
            }
            return 0;
          }),
        );

      return allCategoryMeasure.length !== 0
        ? {
            key: forestType.key,
            topping: {
              data: getYearlyCategoryMeasure(allCategoryMeasure),
              color: forestType.color,
              label: forestType.displayName,
            },
          }
        : undefined;
    })
    .filter((e) => e !== undefined) as BarAreaCategory[];

  const sequestrationYearByYear = sequestrationYearByYearBase.concat(
    FOREST_TYPES_FOR_CHART.compound_data.map((agg) => {
      const constituent_sum = _.reduce(
        sequestrationYearByYearBase
          .filter((v) => agg.aggregate.includes(v.key))
          .map((val) => val.topping.data),
        (sum, n) => sum.map((v, i) => v + n[i]),
      );
      return {
        key: agg.key,
        topping: { color: agg.color, label: agg.displayName, data: constituent_sum ?? [] },
      };
    }),
  );

  const totalSequestration = getBagelToppings(sequestrationYearByYear, [
    'native_ets',
    'native_ccu',
    'exotic_ets',
    'exotic_ccu',
  ]).reduce((sum, seqCat) => sum + seqCat.data, 0);

  const barChartRange = _.range(0, 10).map((i) => ((customers?.start_year ?? 0) + i).toString());
  return (
    <div className="pb-3">
      <div className={`p-2 ${styles.carbon_intelligence_pannel} text-light`}>
        <Row className="m-0 p-3">
          <Col lg={4} className="p-0 gap-2 d-flex align-items-center">
            <div className="d-flex gap-2">
              <div className="fs-3">Carbon Intelligence</div>
              <div className="fs-3 fw-lighter fst-italic">BETA</div>
            </div>
          </Col>
          <Col className="p-0 d-flex flex-column align-items-center" lg={2}>
            <div className="fs-4">{formatNum(sites.length)}</div>
            <small className="fw-lighter fst-italic">total sites</small>
          </Col>
          <Col className="p-0 d-flex flex-column align-items-center" lg={2}>
            <div className="d-flex gap-2 align-items-sm-baseline">
              <div className="fs-4">{formatNum(totalSitesArea)}</div>
              <div className="fs-6">ha</div>
            </div>

            <small className="fw-lighter fst-italic">total area</small>
          </Col>
          <Col className="p-0 d-flex flex-column align-items-center" lg={2}>
            <div className="d-flex gap-2 align-items-sm-baseline">
              <div className="fs-4">{formatNum(totalForestArea)}</div>
              <div className="fs-6">ha</div>
            </div>

            <small className="fw-lighter fst-italic">current forest</small>
          </Col>
          <Col className="p-0 d-flex flex-column align-items-center" lg={2}>
            <div className="d-flex gap-2 align-items-sm-baseline">
              {showtCO2 ? (
                <>
                  <div className="fs-4">{formatNum(totalSequestration)}</div>
                  <div className="fs-6">tCO₂</div>
                </>
              ) : (
                customers &&
                nzuPrice && (
                  <>
                    <div className="fs-4">${formatNum(totalSequestration)}</div>
                  </>
                )
              )}
            </div>
            <small className="fw-lighter fst-italic">
              {showtCO2 ? (
                <> total sequestration {customers?.start_year}</>
              ) : (
                <>total revenue {customers?.start_year}</>
              )}
            </small>
          </Col>
        </Row>
        {showCharts && (
          <>
            <Row className="d-flex flex-column align-items-center">
              <div style={{ minHeight: '0.1em', width: '90%' }} className="bg-white" />
            </Row>
            <Row style={{}} className="py-3 ">
              <Col xs={4} className="d-flex flex-column align-items-center col-xl-4 col-12">
                <div className="d-flex gap-2 align-items-sm-baseline">
                  <p>Current forest</p>
                  <small className="fw-lighter fst-italic">(ha)</small>
                </div>
                <BagelChart
                  toppings={getBagelToppings(forestArea, [
                    'native_ets',
                    'native_ccu',
                    'exotic_ets',
                    'exotic_ccu',
                    'ineligible',
                  ])}
                  hoverLabel="ha"
                />
              </Col>
              <Col xs={4} className="d-flex flex-column align-items-center col-xl-4 col-12">
                <div className="d-flex gap-2 align-items-sm-baseline">
                  <p>{`${showtCO2 ? 'Sequestration' : 'Revenue'} ${customers?.start_year} `}</p>

                  <small className="fw-lighter fst-italic">{showtCO2 ? '(tCO₂)' : `($NZ)`}</small>
                </div>
                {showtCO2 ? (
                  <BagelChart
                    toppings={getBagelToppings(sequestrationYearByYear, [
                      'native_ets',
                      'native_ccu',
                      'exotic_ets',
                      'exotic_ccu',
                    ])}
                    hoverLabel="tCO₂"
                  />
                ) : (
                  <BagelChart
                    toppings={getBagelToppings(sequestrationYearByYear, [
                      'native_ets',
                      'native_ccu',
                      'exotic_ets',
                      'exotic_ccu',
                    ])}
                    hoverLabel="$NZ"
                  />
                )}
              </Col>
              <Col xs={4} className="d-flex flex-column align-items-center col-xl-4 col-12">
                <div className="d-flex gap-2 align-items-sm-baseline">
                  <p>{`${showtCO2 ? 'Current forest sequestration' : 'Current forest revenue'}`}</p>
                  <small className="fw-lighter fst-italic">
                    {showtCO2 ? '(tCO₂/year)' : `($NZ/year)`}
                  </small>
                </div>
                <ChocolateBar
                  toppings={getBarToppings(sequestrationYearByYear, [
                    'native_ets',
                    'native_ccu',
                    'exotic_ets',
                    'exotic_ccu',
                  ])}
                  emptyLabel="No data"
                  xLabels={barChartRange}
                />
              </Col>
            </Row>
            <Row className="py-3 ">
              <Col xs={4} className="d-flex flex-column align-items-center col-xl-4 col-12">
                <div className="d-flex gap-2 align-items-sm-baseline">
                  <p>Future forest</p>
                  <small className="fw-lighter fst-italic">(ha)</small>
                </div>
                <BagelChart
                  toppings={getBagelToppings(forestArea, ['future_native', 'future_exotic'])}
                  hoverLabel="ha"
                />
              </Col>
              <Col xs={4} className="d-flex flex-column align-items-center col-xl-4 col-12">
                <div className="d-flex gap-2 align-items-sm-baseline">
                  <p>{`${showtCO2 ? 'Sequestration' : 'Revenue'}`}</p>
                  <small className="fw-lighter fst-italic">
                    {showtCO2 ? '(tCO₂/year)' : `($NZ/year)`}
                  </small>
                </div>
                <ChocolateBar
                  toppings={getBarToppings(sequestrationYearByYear, [
                    'current_forest_total',
                    'future_forest_total',
                  ])}
                  emptyLabel="No data"
                  xLabels={barChartRange}
                />
              </Col>
              <Col xs={4} className="d-flex flex-column align-items-center col-xl-4 col-12">
                <div className="d-flex gap-2 align-items-sm-baseline">
                  <p>{`${showtCO2 ? 'Future forest sequestration' : 'Future forest revenue'}`}</p>
                  <small className="fw-lighter fst-italic">
                    {showtCO2 ? '(tCO₂/year)' : `($NZ/year)`}
                  </small>
                </div>
                <ChocolateBar
                  toppings={getBarToppings(sequestrationYearByYear, [
                    'future_native_ets',
                    'future_native_ccu',
                    'future_exotic_ets',
                    'future_exotic_ccu',
                  ])}
                  emptyLabel="No data"
                  xLabels={barChartRange}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
      <Row>
        <Col xs={4} />
        <Col xs={4} className="d-flex flex-column align-items-center">
          <FontAwesomeIcon
            icon={showCharts ? faAngleUp : faAngleDown}
            size="lg"
            className="rounded py-0 cursor-pointer"
            style={{
              transform: 'translate(0px, -50%)',
              backgroundColor: cc_charcoal,
              width: '2em',
            }}
            color="white"
            onClick={() => {
              setShowCharts(!showCharts);
            }}
          />
        </Col>
        <Col xs={4} className="d-flex flex-column align-items-end">
          {showCharts && (
            <div
              style={{
                transform: 'translate(0px, -60%)',
              }}
              className="pe-4"
            >
              <a
                href={`https://docs.google.com/forms/d/e/1FAIpQLScmdm9-CxMSz-11Y2FYrKZl0H_LStGOf4tFFc8Tyyw9ACdV-A/viewform?usp=pp_url&entry.524841042=${userMe?.email}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: cc_lime,
                  fontSize: '0.85em',
                  backgroundColor: cc_charcoal,
                }}
                className="px-3 py-1 rounded d-flex gap-2 text-decoration-none"
              >
                <div>Give feedback</div>
                <FontAwesomeIcon
                  icon={faCommentDots}
                  size="lg"
                  color={cc_lime}
                  onClick={() => {
                    setShowCharts(!showCharts);
                  }}
                />
              </a>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CarbonIntelligencePanel;

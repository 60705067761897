import { useApiService } from '../../apis/api-service';
import { CompanyCustomerTags, CompanyUserTags } from '../../apis/generated';
import { useStore } from './sources/useStore';
import { useUserMeCompanies } from './useUserMeCompanies';

export const useCustomerCompanyCarbonManager = (customer_id: number | undefined) => {
  const apiService = useApiService();

  const { data, setData, state, error } = useStore({
    key: `/api/v1/customers/${customer_id}/companies/carbon_manager`,
    getFromServer: async () => {
      if (customer_id === undefined) {
        return undefined;
      }

      const data = await apiService.getCustomerCompany(
        customer_id,
        CompanyCustomerTags.CARBON_MANAGER,
      );
      if (data === null) {
        // If the customer does not exist
        return undefined;
      }
      return data;
    },
    saveToServer: async (_server_data, client_data) => {
      if (client_data === undefined || customer_id === undefined) {
        return client_data;
      }
      await apiService.postCustomerCompany(
        customer_id,
        CompanyCustomerTags.CARBON_MANAGER,
        client_data,
      );
      return client_data;
    },
  });

  return {
    carbonManager: data,
    setCarbonManager: setData,
    state,
    error,
  };
};

/**
 * Returns the relationship between the current user and the carbon manager of this
 * site/customer.
 *
 * For example if site 123 is managed by CarbonIsUs.com, and the user is an employee
 * of CarbonIsUs.com, then this will return ['employee'] as that is the relationship between
 * site 123 and CarbonIsUs.com.
 */
export const useRelationToCarbonManager = (customerId: number): CompanyUserTags[] | undefined => {
  const { userMeCompanies } = useUserMeCompanies();
  const { carbonManager } = useCustomerCompanyCarbonManager(customerId);

  if (!carbonManager || !userMeCompanies) {
    return undefined;
  }
  const company = userMeCompanies.companies.find(
    (company) => company.company_id === carbonManager.company_id,
  );
  if (!company) {
    return undefined;
  }
  return company.tags;
};

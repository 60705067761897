import { useParams } from 'react-router';

export const useCurrentCompanyId = (): number => {
  const { companyId } = useParams();

  if (!companyId) {
    throw new Error(
      'The useCurrentCompanyId hook can only be used in a page that contains a companyId path parameter',
    );
  }
  return Number(companyId);
};

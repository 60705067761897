export const getRunningEnv = (): 'development' | 'production' | 'test' => {
  if (window.location.host.startsWith('app.carboncrop.') && process.env.NODE_ENV === 'production') {
    return 'production';
  }
  if (
    window.location.host.startsWith('test-app.carboncrop.') &&
    process.env.NODE_ENV === 'production'
  ) {
    return 'test';
  }

  return 'development';
};

import _ from 'lodash';
import pLimit from 'p-limit';
import { getValue, makeHistory, redo, setValueDebounced, undo, UndoHistory } from '../../history';

export type StoreState = 'uploading' | 'downloading' | 'idle' | 'error';

export type OnDataChanged<T> = (
  data: T | undefined,
  state: StoreState,
  errors: Error | undefined,
) => void;
export type UnsubscribeFn = () => void;

export type GetFromServerFn<T> = (current: T | undefined) => Promise<T>;
export type SaveToServerFn<T> = (serverData: T, clientData: T) => Promise<T>;

export type UpdaterFunction<T> = (data: T) => T;
export type ModifierFunction<T> = (mod: UpdaterFunction<T>) => void;

const LOADING = Symbol('loading');
type LoadableData<T> = typeof LOADING | T;

/**
 * A store is used to synchronize data across an asynchronous data source (eg a server, a long running process, local/session storage etc.)
 * It maintains a local copy of the data which can be mutated independently of the data source.
 *
 * Conflicts are resolved in a "local source wins" manner. This means that if the local data is changed
 * while the data is being downloaded from the server, the local data will be uploaded to the server and the
 * server data will be discarded.
 */
export class Store<T> {
  private subscribers: Set<OnDataChanged<T>> = new Set();
  private isHotSubscribers = new Set<(isHot: boolean) => void>();
  private guard = pLimit(1);
  private history?: UndoHistory<T>;

  // Note that the state doesn't actually influence anything. It is only
  // used for display purposes to the outside world
  private externallyVisibleState: StoreState = 'idle';
  private error: Error | undefined = undefined;

  constructor(
    private syncers: {
      /**
       * The saveToServerFunction is responsible for uploading data to the server.
       * Exactly how it does this is left up to the implementer. It can do it all as one object
       * or it can split it into multiple API calls.
       * This function can return a new version of the data that was uploaded. This is useful
       * if the server has made any changes to the data (e.g. adding a timestamp or ID)
       * If no changes have been made, it should return the passed in clientData
       */
      saveToServer?: SaveToServerFn<T>;

      /**
       * The getFromServer function is responsible for downloading data from the server.
       */
      getFromServer?: GetFromServerFn<T>;
    },

    // If we already have the data from somewhere, we can construct this in a partially loaded state
    private clientData: LoadableData<T> = LOADING,
    private serverData: LoadableData<T> = LOADING,
    private trackHistory = false,
  ) {
    // intentionally left blank
  }

  /**
   * @returns true if there are any subscribers to this store
   */
  public isHot(): boolean {
    return this.subscribers.size > 0;
  }

  private updateIsHot() {
    const isHot = this.isHot();
    this.isHotSubscribers.forEach((sub) => sub(isHot));
  }

  /**
   * This registers a function that will be called whenever the data in the
   * store changes. It will also call the function immediately with the current
   * data.
   *
   * @param fn A function that will be called whenever the data changes
   * @returns
   */
  public subscribe(fn: OnDataChanged<T>): UnsubscribeFn {
    this.subscribers.add(fn);
    this.updateIsHot();

    fn(
      this.clientData !== LOADING ? this.clientData : undefined,
      this.externallyVisibleState,
      this.error,
    );
    return () => {
      this.subscribers.delete(fn);
      this.updateIsHot();
    };
  }

  /**
   * Register a function to be called whenever the store switches from being hot (has something subscribe to it)
   * to being cold (has nothing subscribed to it) or vice versa.
   * @param fn Subscriber function
   * @returns
   */
  public onIsHotChanged(fn: (subscribers: boolean) => void): UnsubscribeFn {
    this.isHotSubscribers.add(fn);
    fn(this.isHot());
    return () => this.isHotSubscribers.delete(fn);
  }

  /**
   * Mutate the data stored inside the store. This will trigger an upload of the data to the server and
   * update any subscribers.
   *
   * An updater function is used to prevent issues with out-of-order operations. Generally this function
   * should make the minimum of changes to the data and return the new data.
   * @param updater A function that takes the current data and returns the new data
   */
  public setData(updater: UpdaterFunction<T>): void {
    if (this.clientData === LOADING) {
      throw new Error('Tried to call setData before download was complete');
    }
    this.setClientData(updater(this.clientData));
    if (this.history) {
      this.history = setValueDebounced(this.history, this.clientData);
    }
    this.upload();
  }

  /**
   * Return the data stored in the store. This will be undefined until the first download has completed.
   */
  public getData(): T | undefined {
    if (this.clientData === LOADING) {
      return undefined;
    }
    return this.clientData;
  }

  /**
   * Returns if the data is in sync between the client and the server.
   * This is useful for displaying state to the user
   */
  public isDirty(): boolean {
    return this.clientData !== this.serverData;
  }

  private upload = _.debounce(
    () => {
      void this.guard(async () => {
        if (
          this.serverData === LOADING ||
          this.clientData === LOADING ||
          this.clientData === this.serverData
        ) {
          return;
        }
        if (this.syncers.saveToServer === undefined) {
          throw new Error('No saveToServer function provided');
        }

        try {
          this.setState('uploading');
          const toUpload = this.clientData;
          const newServerData = await this.syncers.saveToServer(this.serverData, this.clientData);
          this.serverData = newServerData;
          if (toUpload === this.clientData) {
            this.setClientData(this.serverData);
          }
          this.setState('idle');
        } catch (e) {
          this.setError(e);
          console.warn('Failed to save to server: ', e);
        }
      });
    },
    1000,
    { leading: true, maxWait: 15000 },
  );

  /**
   * Check the server for new data. If there are already local changes this function
   * is a no-op. If local changes are made before download is complete, the downloaded
   * data will be discarded.
   */
  public async download(): Promise<void> {
    await this.guard(async () => {
      if (this.clientData !== this.serverData) {
        // Local changes - no point downloading as they would just get overwritten
        return;
      }
      if (this.syncers.getFromServer === undefined) {
        throw new Error('No getFromServer function provided');
      }
      try {
        this.setState('downloading');
        const newData = await this.syncers.getFromServer(
          this.serverData !== LOADING ? this.serverData : undefined,
        );
        this.handleNewServerData(newData);
        this.setState('idle');
      } catch (e) {
        this.setError(e);
        console.warn('Failed to download from server: ', e);
        throw e;
      }
    });
  }

  public undo(): void {
    if (!this.trackHistory) {
      throw new Error('Cannot redo when history is not tracked');
    }
    if (this.history) {
      this.history = this.history && undo(this.history);
      this.clientData = getValue(this.history);
      this.triggerSubscribers();
      this.upload();
    }
  }

  public redo(): void {
    if (!this.trackHistory) {
      throw new Error('Cannot undo when history is not tracked');
    }
    if (this.history) {
      this.history = this.history && redo(this.history);
      this.clientData = getValue(this.history);
      this.triggerSubscribers();
      this.upload();
    }
  }

  private handleNewServerData(newData: T) {
    if (this.clientData === this.serverData) {
      if (this.trackHistory && !this.history) {
        this.history = makeHistory(newData);
      }
      this.setClientData(newData);
    }
    this.serverData = newData;
  }

  /** Waits until all currently pending uploads and downloads have completed */
  public async settle(): Promise<void> {
    await this.guard(async () => undefined);
  }

  private triggerSubscribers() {
    this.subscribers.forEach((subscriber) => {
      subscriber(this.getData(), this.externallyVisibleState, this.error);
    });
  }

  private setError(error: Error) {
    this.error = error;
    this.setState('error'); // This triggers subscribers
  }

  private setClientData(data: LoadableData<T>) {
    this.clientData = data;
    this.triggerSubscribers();
  }

  private setState(state: StoreState) {
    this.externallyVisibleState = state;
    this.triggerSubscribers();
  }
}

import { useCallback } from 'react';
import { useApiService } from '../apis/api-service';
import { CompanyUserResponse, CompanyUserTags } from '../apis/generated';
import { updateOrInsertInArray } from '../array_tools';
import { useStore } from '../contexts/remote-data/sources/useStore';
import { computeDiff } from '../contexts/remote-data/useInvoice';

export type ActionCategory = 'inviting_user' | 'removing_user' | 'idle';

export interface CompanyUser extends Omit<CompanyUserResponse, 'user_id'> {
  action: ActionCategory;
  user_id: number | undefined;
}

export const useCompanyUsers = (company_id: number | undefined) => {
  const apiService = useApiService();

  const { data, setData, state, error, download, ...rest } = useStore<CompanyUser[]>({
    key: `/api/v1/companies/${company_id}/users`,
    getFromServer: async () => {
      if (company_id === undefined) {
        return [];
      }
      const raw = await apiService.getCompanyUsers(company_id);
      return raw.map((u) => ({ ...u, action: 'idle' }));
    },
    saveToServer: async (serverData, clientData) => {
      if (company_id === undefined) {
        return [];
      }
      const { toCreate, toDelete, toUpdate } = computeDiff(
        serverData,
        clientData,
        (i) => i.email || i.email,
      );

      let newServerData = [...serverData];

      // eslint-disable-next-line no-restricted-syntax
      for (const user of toCreate) {
        const updatedUser = await apiService.postCompanyUsersInvitation(company_id, {
          email: user.email,
          company_user_tags: user.tags,
        });

        newServerData = updateOrInsertInArray(
          newServerData,
          {
            ...updatedUser,
            action: 'idle',
          },
          (a, b) => a.email === b.email,
        ); // Match on email as new users don't have user_id's yet
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const user of toDelete) {
        if (user.user_id !== undefined) {
          await apiService.deleteCompanyUserTagsAll(company_id, user.user_id);
          newServerData = newServerData.filter((u) => u.user_id !== user.user_id);
        }
      }

      toUpdate.forEach(async () => {
        throw new Error('Not implemented');
        // Just update tags
      });

      return newServerData;
    },
  });

  const addUser = useCallback(
    (email: string, tags: CompanyUserTags[]) => {
      setData((users) => {
        const newUser: CompanyUser = {
          user_id: undefined,
          email,
          tags,
          action: 'inviting_user',
        };

        const newUserList = [...users, newUser];
        return newUserList;
      });
    },
    [setData],
  );

  const removeUser = useCallback(
    (user_id: number) => {
      setData((users) => users.filter((u) => u.user_id !== user_id));
    },
    [setData],
  );

  return {
    users: data,
    setUsers: setData,
    state,
    error,
    download,
    addUser,
    removeUser,
    ...rest,
  };
};

import { useApiService } from '../../apis/api-service';
import { useStore } from './sources/useStore';

export const useCarbonCropCCUForecast = () => {
  const apiService = useApiService();

  const { data, state, download, error } = useStore({
    key: ' /api/v1/ccu_forecast',
    getFromServer: async () => {
      return apiService.getCcuForecast();
    },
  });

  return {
    carbonCropCCUForecast: data,
    state,
    error,
    download,
  };
};

import React from 'react';
import styles from './CheckMark.module.scss';

interface CheckMarkProps {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  readOnly?: boolean;
}

/**
 * Replaces use of <input type="checkbox"> with one with the
 * carboncrop styling
 */
export const CheckMark: React.FC<CheckMarkProps> = ({
  checked,
  onChange,
  children,
  className = '',
  style = {},
  disabled = false,
  readOnly = false,
}) => {
  return (
    <label
      className={`d-flex align-items-center ${styles.checkContainer} ${className}`}
      style={{ marginBottom: '16px', ...style }}
    >
      <input
        type="checkbox"
        disabled={disabled}
        readOnly={readOnly}
        checked={checked}
        onChange={onChange && ((e) => onChange(e.target.checked))}
      />
      {children}
      <span className={styles.checkmark}> </span>
    </label>
  );
};

export const LargeBlueCheckMark: React.FC<CheckMarkProps> = ({
  checked,
  onChange,
  children,
  className = '',
  style = {},
  disabled = false,
  readOnly = false,
}) => {
  return (
    <label className={className} style={style}>
      <input
        type="checkbox"
        disabled={disabled}
        readOnly={readOnly}
        checked={checked}
        onChange={onChange && ((e) => onChange(e.target.checked))}
        style={{
          width: '1em',
          height: '1em',
        }}
      />
      {children}
    </label>
  );
};

import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { WordMark } from '../components/branding/WordMark';

export const ErrorPageContainer: React.FC = ({ children }) => {
  return (
    <div
      style={{
        backgroundImage: 'url(/images/bg_carboncrop_1.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
      }}
      className="p-0 background-image h-100"
    >
      <Container style={{ maxWidth: '560px' }}>
        <Row>
          <Col>
            <WordMark variant="dark" />
          </Col>
        </Row>
        <Row className="text-center bg-white p-4">
          <Col>{children}</Col>
        </Row>
      </Container>
    </div>
  );
};

/* eslint-disable camelcase */
import React from 'react';
import uuid from 'uuid';
import '../theme/carboncrop.scss';

import { useNavigate } from 'react-router-dom';
import { GenericTask } from '../apis/generated';
import { useCurrentCustomerIdMaybe } from '../contexts/remote-data/useCustomerIdMaybe';
import { useSiteTasks } from '../contexts/remote-data/useSiteTasks';
import { taskDefinitionsByType } from '../customer/overview/TasksDefinition';
import ReportCard from '../report/ReportCard';
import { useUserMe } from '../contexts/remote-data/useUserMe';

const TaskCard: React.FC<{
  taskType: GenericTask['task_type'];
}> = ({ taskType }) => {
  const customerId = useCurrentCustomerIdMaybe();
  const { setTasks } = useSiteTasks(customerId ?? -1);
  const navigate = useNavigate();
  const { userMe } = useUserMe();
  const taskDefinition = taskDefinitionsByType[taskType];

  const createCustomTask = (type: GenericTask['task_type'], notes: string) => {
    if (customerId === undefined) {
      return;
    }
    const task_id = uuid.v4();
    setTasks((tasks) => {
      return tasks.concat([
        {
          uuid: task_id,
          customer_id: customerId,
          status: 'todo',
          task_type: type,
          assignee: userMe?.user_id,
          properties: { notes: notes },
        },
      ]);
    });
    navigate(`/customer/${customerId}/tasks/${task_id}`);
  };
  return (
    <ReportCard
      title={taskDefinition.typeName}
      description={taskDefinition.description}
      icon={taskDefinition.icon}
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        createCustomTask(taskType, taskDefinition.defaultNotes);
      }}
    />
  );
};

export default TaskCard;

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ModifierFunction, StoreState, UpdaterFunction } from './Store';
import { StoreDefinition, StoresContext } from './StoreContext';

export interface UseStoreResult<T> {
  /** The data currently stored locally */
  data: T | undefined;

  /** Update the local data (and trigger a push to the data source) */
  setData: ModifierFunction<T>;

  /** An inferred state of what the store is doing. This is mainly useful
   * for visual feedback to the user. */
  state: StoreState;

  /** Force a download of the data from the data source. If you know the
   * data has changed, call this!
   */
  download: () => Promise<void>;

  /** If there was an error, it will be stored here. This can be both upload
   * or download errors
   */
  error: Error | undefined;

  undo: () => void;
  redo: () => void;
}

/**
 * Finds or creates a store in the StoresContext and subscribes to changes to it.
 *
 * @param storeDefintion How to idenfity and create a store.
 * @returns
 */
export const useStore = <T>(storeDefintion: StoreDefinition<T>): UseStoreResult<T> => {
  const { getOrCreateStore } = useContext(StoresContext);

  const store = useMemo(() => {
    return getOrCreateStore(storeDefintion);
  }, [storeDefintion, getOrCreateStore]);
  const [state, setState] = useState<StoreState>('idle');
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<T>();

  useEffect(
    () =>
      store.subscribe((p, state, error) => {
        setData(p);
        setState(state);
        setError(error);
      }),
    [setData, state, store],
  );

  const download = useCallback(async () => {
    // This is needed to avoid debinding `this` which would happen if we passed
    // `store.download` directly out of the hook.
    await store.download();
  }, [store]);

  const updater = useCallback(
    (updater: UpdaterFunction<T>) => {
      store.setData(updater);
    },
    [store],
  );

  return {
    data,
    setData: updater,
    state,
    download,
    error,
    undo: () => store.undo(),
    redo: () => store.redo(),
  };
};

/**
 * Returns a getter and setter for a property inside an object (given a key and setter)/
 */
export const useProperty = <T extends object, U extends keyof T>(
  getset: [T | undefined, ModifierFunction<T | undefined>],
  field: U,
  defaultVal: T[U],
): [T[U] | undefined, ModifierFunction<T[U]>] => {
  const [val, set] = getset;

  const setter: ModifierFunction<T[U]> = useCallback(
    (mod: UpdaterFunction<T[U]>) => {
      set((v: T | undefined): T | undefined => {
        if (v === undefined) {
          return undefined;
        }
        return {
          ...v,
          [field]: mod(v[field] ?? defaultVal),
        };
      });
    },
    [set, field, defaultVal],
  );

  return [val === undefined ? undefined : val[field] ?? defaultVal, setter];
};

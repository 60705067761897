import { useCallback, useMemo } from 'react';
import { useApiService } from '../../apis/api-service';
import { InvoiceTask, GetCustomerTasksResponse } from '../../apis/generated/index';
import { updateOrInsertInArray } from '../../array_tools';
import { UpdaterFunction } from './sources/Store';
import { useStore } from './sources/useStore';
import { computeDiff } from './useInvoice';

// Every time we add a task type we have to add it here as well:
export type AnyTaskType = GetCustomerTasksResponse[0]['task_type'];
export type AnyTask = GetCustomerTasksResponse[0];

export const useSiteTasks = (site_id: number) => {
  const apiService = useApiService();

  const { data, setData, state, ...rest } = useStore<AnyTask[]>({
    key: `/customers/${site_id}/tasks`,
    autoSync: true,
    autoSyncPeriod: 2e3, // Sync per 2 seconds
    // Are you here because you see an "is not assignable to type 'AnyTask[]'" error?
    // It's probably because you added a new task type and, it it still needs to be
    // added to the `AnyTask` type.
    getFromServer: async () => apiService.getCustomerTasks(site_id),
    saveToServer: async (serverTasks, clientTasks) => {
      const { toCreate, toDelete, toUpdate } = computeDiff(serverTasks, clientTasks, (i) => i.uuid);

      let newServerTasks = serverTasks;

      // eslint-disable-next-line no-restricted-syntax
      for (const task of [...toCreate, ...toUpdate.map((t) => t.newObj)]) {
        await apiService.putCustomerTasks(site_id, task);
        newServerTasks = updateOrInsertInArray(newServerTasks, task, (a, b) => a.uuid === b.uuid);
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const task of toDelete) {
        await apiService.deleteCustomerTask(site_id, task.uuid);
        newServerTasks = newServerTasks.filter((t) => t.uuid !== task.uuid);
      }

      return newServerTasks;
    },
  });

  return {
    tasks: data,
    setTasks: setData,
    state,
    ...rest,
  };
};

/**
 * This is a type guard for the InvoiceTask type, allowing it to be
 * distinguished from other task types.
 */
const isInvoiceTask = (task: AnyTask): task is InvoiceTask => {
  return task.task_type === 'invoice';
};

/**
 * Given a list of tasks and a UUID, find the task with that UUID so long
 * as it is an InvoiceTask.
 *
 * If there is not task with the UUID, or if the UUID is not an InvoiceTask,
 * this function will return undefined.
 */
const findInvoiceTask = (tasks: AnyTask[] | undefined, uuid: string): InvoiceTask | undefined => {
  const invoiceTasks = tasks?.filter(isInvoiceTask);
  return invoiceTasks?.find((t) => t.uuid === uuid);
};

export const useInvoiceTask = (site_id: number, task_uuid: string) => {
  const { tasks, setTasks, ...rest } = useSiteTasks(site_id);

  const task = useMemo(() => findInvoiceTask(tasks, task_uuid), [tasks, task_uuid]);

  const setTask = useCallback(
    (updater: UpdaterFunction<InvoiceTask>) => {
      setTasks((t) => {
        const old = findInvoiceTask(t, task_uuid);
        if (!old) {
          return t;
        }

        const newTask = updater(old);
        return updateOrInsertInArray<AnyTask>(t, newTask, (t: AnyTask) => t.uuid === task_uuid);
      });
    },
    [setTasks, task_uuid],
  );

  return {
    task,
    setTask,
    ...rest,
  };
};

/**
 * Returns a copy of an array of item T where some items have been updated to a new value
 * using a matching function to determine which element to update.
 *
 * @param arr
 * @param updatedItem
 * @param equality_predicate
 * @param bool
 */
export const updateInArray = <T>(
  arr: T[],
  updatedItem: T,
  equality_predicate: (a: T, b: T) => boolean,
): T[] => {
  return arr.map((oldItem) => (equality_predicate(oldItem, updatedItem) ? updatedItem : oldItem));
};

export const updateOrInsertInArray = <T>(
  arr: T[],
  updatedItem: T,
  equality_predicate: (a: T, b: T) => boolean,
): T[] => {
  const isInArray = arr.some((oldItem) => equality_predicate(oldItem, updatedItem));
  if (isInArray) {
    return updateInArray(arr, updatedItem, equality_predicate);
  }
  return [...arr, updatedItem];
};

/**
 * If you find yourself calling updateInArray lots with the same predicate, then you can use
 * createArrayUpdater to pre-fill that for you.
 *
 * This factory returns a function that will do a find/replace on an array. All instances that
 * match predicate will be replaced with `updatedItem`
 * @param equalityPredicate
 * @returns
 */
export const createArrayUpdater = <T>(
  equalityPredicate: (a: T, b: T) => boolean,
): ((arr: T[], updatedItem: T) => T[]) => {
  return (arr: T[], updatedItem: T): T[] => {
    return updateInArray(arr, updatedItem, equalityPredicate);
  };
};

/**
 * Returns a copy of an array of item T where some items have been updated to a new value
 * using a matching function to determine which element to update.
 */
export const updateWhere = <T>(
  arr: T[],
  where: (item: T) => boolean,
  update: (item: T) => T,
): T[] => {
  const result = arr.map((item) => (where(item) ? update(item) : item));
  // If no elements changed, then return the original array. This is to avoid infinite
  // updates when the update function is a no-op.
  const anyChanged = result.some((item, index) => item !== arr[index]);
  return anyChanged ? result : arr;
};

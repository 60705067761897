import { useApiService } from '../../apis/api-service';
import { ApiError } from '../../apis/generated';
import { useStore } from './sources/useStore';
import { useCurrentCompanyIdUltimate } from './useCurrentCompanyIdUltimate';
import { useCurrentCustomerIdMaybe } from './useCustomerIdMaybe';

export const useAccess = () => {
  const customerId = useCurrentCustomerIdMaybe();
  const companyId = useCurrentCompanyIdUltimate();
  const apiService = useApiService();

  if (customerId === undefined && companyId === undefined) {
    throw new Error('No customer or company ID in the URL');
  }

  const { data, state } = useStore({
    key: `/v1/customers/${customerId}/access` || `/v1/companies/${companyId}/access`,
    getFromServer: async () => {
      try {
        if (customerId !== undefined) {
          return await apiService.getCustomerAccess(customerId);
        }
        if (companyId !== undefined) {
          return await apiService.getCompanyAccess(companyId);
        }
        return {
          available_features: undefined,
        };
      } catch (error) {
        const isForbidden = error instanceof ApiError && error.status === 403;
        if (isForbidden) {
          return {
            available_features: [],
          };
        }
        throw error;
      }
    },
  });

  return {
    access: data,
    state,
  };
};

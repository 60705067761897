import React from 'react';
import { Button, Image } from 'react-bootstrap';
import Fantail from '../theme/images/branding/birds/fantail.png';
import { ErrorPageContainer } from './Container';

export const UnexpectedError = () => {
  return (
    <ErrorPageContainer>
      <h2>Unexpected Error</h2>
      <Image src={Fantail} style={{ width: '160px' }} />

      <p>Well that’s embarrassing! Please reload the page and try again.</p>
      <p>
        Is the issue persisting? <a href="https://www.carboncrop.com/contact">Get in touch</a>
      </p>
      <Button variant="primary" onClick={() => window.location.reload()}>
        Reload
      </Button>
    </ErrorPageContainer>
  );
};

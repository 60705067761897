import { useCallback, useMemo } from 'react';
import { updateInArray } from '../../array_tools';
import { Invoice } from '../../models/Invoice';
import { useStore } from './sources/useStore';
import { useApiService } from '../../apis/api-service';
import { InvoiceStatus } from '../../apis/generated';

/// Returns the keys of an object that have changed between two objects.
/// This is done via reference equality, so make sure you are doing things
/// functional
export const listChangedKeys = <T extends object>(o1: T, o2: T): (keyof T)[] => {
  const allKeys = Object.keys(o1).concat(Object.keys(o2)) as (keyof T)[];
  return [...new Set(allKeys)].filter((key) => o1[key] !== o2[key]);
};

export const computeDiff = <T,>(oldObjs: T[], newObjs: T[], genId: (o: T) => string | number) => {
  // Extract the changes....
  const toCreate: T[] = newObjs.filter((p) => !oldObjs.find((op) => genId(op) === genId(p)));
  const toDelete: T[] = oldObjs.filter((p) => !newObjs.find((np) => genId(np) === genId(p)));
  const toUpdate: Array<{ oldObj: T; newObj: T }> = newObjs.flatMap((newObj) => {
    const oldObj = oldObjs.find((op) => genId(op) === genId(newObj));
    if (oldObj === newObj) {
      return [];
    }
    return oldObj ? [{ oldObj, newObj }] : [];
  });

  return { toCreate, toDelete, toUpdate };
};

export const useInvoices = (customerId: number) => {
  const apiService = useApiService();

  const { data, setData, ...rest } = useStore({
    key: `/customer/${customerId}/invoices`,
    autoSync: true,
    autoSyncPeriod: 2e3, // Sync per 2 seconds
    getFromServer: async () => apiService.old.getCustomerInvoices(customerId),
    saveToServer: async (serverInvoices: Invoice[], clientInvoices: Invoice[]) => {
      const { toCreate, toDelete, toUpdate } = computeDiff<Invoice>(
        serverInvoices,
        clientInvoices,
        (i: Invoice) => i.local_id || i.invoice_id,
      );

      let newServerInvoices = serverInvoices;

      // eslint-disable-next-line no-restricted-syntax
      for (const invoice of toCreate) {
        const postInvoiceResponse = await apiService.old.postCustomerInvoice(customerId, invoice);
        newServerInvoices = [
          ...newServerInvoices,
          {
            local_id: invoice.local_id,
            ...postInvoiceResponse,
          },
        ];
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const { oldObj, newObj } of toUpdate) {
        await apiService.putCustomerInvoiceStatus(oldObj.invoice_id, customerId, {
          status: newObj.status,
        });
        const newServerObj = await apiService.putCustomerInvoiceProperties(
          oldObj.invoice_id,
          customerId,
          newObj.properties,
        );

        const newInvoice: Invoice = {
          ...newServerObj,
          properties: {
            ...newServerObj.properties,
            service_fee_proportion: newServerObj.properties.service_fee_proportion.toString(),
            cc_service_fee: {
              ...newServerObj.properties.cc_service_fee,
              quantity: newServerObj.properties.cc_service_fee.quantity.toString(),
              total_ex_tax: newServerObj.properties.cc_service_fee.total_ex_tax.toString(),
            },
            payment: {
              ...newServerObj.properties.payment,
              balance_brought_forward:
                newServerObj.properties.payment.balance_brought_forward.toString(),
              total_payable: newServerObj.properties.payment.total_payable.toString(),
              total_paid_now_in_nzu:
                newServerObj.properties.payment.total_paid_now_in_nzu.toString(),
              total_paid_now_in_nzd:
                newServerObj.properties.payment.total_paid_now_in_nzd.toString(),
              balance_carried_forward:
                newServerObj.properties.payment.balance_carried_forward.toString(),
            },
            service_fee_calculation: {
              ...newServerObj.properties.service_fee_calculation,
              gross_yield: newServerObj.properties.service_fee_calculation.gross_yield.toString(),
              costs_ex_tax: newServerObj.properties.service_fee_calculation.costs_ex_tax.toString(),
              gross_yield_net_costs:
                newServerObj.properties.service_fee_calculation.gross_yield_net_costs.toString(),
              service_fee_ex_tax:
                newServerObj.properties.service_fee_calculation.service_fee_ex_tax.toString(),
            },
            nzus_issued: {
              ...newServerObj.properties.nzus_issued,
              amount: newServerObj.properties.nzus_issued.amount.toString(),
              nzu_price: newServerObj.properties.nzus_issued.nzu_price.toString(),
            },
            costs_and_disbursements: newServerObj.properties.costs_and_disbursements?.map((c) => ({
              ...c,
              quantity: c.quantity.toString(),
              total_ex_tax: c.total_ex_tax.toString(),
            })),
            already_invoiced_costs_and_disbursements:
              newServerObj.properties.already_invoiced_costs_and_disbursements?.map((c) => ({
                ...c,
                quantity: c.quantity.toString(),
                total_ex_tax: c.total_ex_tax.toString(),
              })),
          },
          local_id: newObj.local_id,
          invoice_id: newServerObj.invoice_id,
          total_ex_tax: newServerObj.total_ex_tax.toString(),
          total_inc_tax: newServerObj.total_inc_tax.toString(),
          tax: newServerObj.tax.toString(),
          status: newServerObj.status === 'draft' ? InvoiceStatus.DRAFT : InvoiceStatus.SENT,
        } as Invoice;

        newServerInvoices = updateInArray(
          newServerInvoices,
          newInvoice,

          (a, b) =>
            (a.local_id && b.local_id && a.local_id === b.local_id) ||
            a.invoice_id === b.invoice_id,
        );
      }

      // eslint-disable-next-line no-restricted-syntax
      if (toDelete.length > 0) {
        throw new Error('Cannot delete invoices');
      }

      return newServerInvoices;
    },
  });

  return {
    invoices: data,
    setInvoices: setData,
    ...rest,
  };
};

export const useInvoice = (customerId: number, invoiceId: number | undefined) => {
  const { invoices, setInvoices, ...rest } = useInvoices(customerId);

  const invoice = useMemo(
    () => invoices?.find((i) => i.invoice_id === invoiceId),
    [invoices, invoiceId],
  );

  const setInvoice = useCallback(
    (updater: (i: Invoice) => Invoice) => {
      if (!invoice) {
        return;
      }

      setInvoices((invoices: Invoice[] | undefined) => {
        const newInvoices = updateInArray(
          invoices ?? [],
          updater(invoice),
          (a, b) => a.invoice_id === b.invoice_id,
        );
        return newInvoices;
      });
    },
    [setInvoices, invoice],
  );

  return {
    invoice,
    setInvoice,
    ...rest,
  };
};

import React from 'react';
import stc from 'string-to-color';
import styles from './ProfileBubble.module.scss';
import { cc_offwhite } from '../../theme/colors';

interface ProfileBubbleProps {
  full_name?: string;
  email?: string;
  size?: 'xs' | 'sm' | 'lg';
  hoverShadow?: boolean;
}

const BubbleSize = {
  xs: '0.6rem',
  sm: '1rem',
  lg: '2rem',
};

export const ProfileBubble: React.FC<ProfileBubbleProps> = ({
  full_name,
  email,
  size = 'sm',
  hoverShadow = false,
}: ProfileBubbleProps) => {
  let iconText: string[] = [];
  if (full_name) {
    iconText = full_name?.split(' ').map((name) => name[0]?.toUpperCase());
  } else if (email) {
    iconText = email
      ?.split('@')[0]
      .split('.')
      .map((name) => name[0]?.toUpperCase());
  }
  const backgroundColor = email ? stc(email) : '#ddd';
  return (
    <div
      className={`flex-shrink-0 nav-item-badge text-center fw-normal ${styles.profileImage} ${
        hoverShadow && styles.hoverShadow
      }`}
      style={{
        fontSize: BubbleSize[size || 'lg'],
        backgroundColor,
        height: '2.5em',
        width: '2.5em',
        color: cc_offwhite,
      }}
    >
      {iconText.slice(0, 2) || '-'}
    </div>
  );
};

import React from 'react';
import { Form } from 'react-bootstrap';

import '../FinancialsTab.scss';

const CO2NZDToggle: React.FC<{
  showtCO2: boolean;
  setShowtCO2: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ showtCO2, setShowtCO2 }) => {
  return (
    <div className="d-flex gap-1  justify-content-center  align-items-center">
      <div className="px-2">$NZ</div>
      <Form.Check
        type="switch"
        id="chkIsIncluded"
        className="form-check-always-green"
        checked={showtCO2}
        onChange={(e) => setShowtCO2(e.target.checked)}
      />
      <div>tCO₂</div>
    </div>
  );
};

export default CO2NZDToggle;

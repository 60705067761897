/**
 * We have a couple drag-drop components that all have different logic but need a consistent
 * visual style.
 *
 * This is a styling div that has the hover effect etc.
 *  */

import React from 'react';
import cx from 'classnames';

export interface DragDropStyledProps extends React.HTMLAttributes<HTMLDivElement> {
  visualState: 'idle' | 'dragging';
}

export const DragDropStyled: React.FC<DragDropStyledProps> = (props) => {
  const { visualState, className, children, style, ...otherProps } = props;
  return (
    <div
      {...{
        ...otherProps,
        style: { zIndex: 1, ...style },
        className: `border border-3 border-dashed position-relative ${className}`,
      }}
    >
      <div
        className={cx('position-absolute top-0 bottom-0 start-0 end-0 opacity-25', {
          'bg-info': visualState === 'dragging',
          'bg-birch': visualState === 'idle',
        })}
        style={{ zIndex: -1 }}
      />
      {children}
    </div>
  );
};

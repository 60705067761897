import { useApiService } from '../../apis/api-service';
import {
  GetCompanyCustomersV2Response,
  SitesListResponse,
  SiteSummary,
} from '../../apis/generated';
import { useStore } from './sources/useStore';
import { computeDiff } from './useInvoice';

export const useCompaniesCustomersV2 = (company_id: number) => {
  const apiService = useApiService();

  const { data, setData, state, ...rest } = useStore({
    key: `/companies/${company_id}/customers/v2`,
    getFromServer: async () => apiService.getCompanyCustomersV2(company_id),
    saveToServer: async (
      serverCustomers: GetCompanyCustomersV2Response,
      clientCustomers: GetCompanyCustomersV2Response,
    ): Promise<SitesListResponse> => {
      const { toUpdate } = computeDiff<SiteSummary>(
        serverCustomers.sites,
        clientCustomers.sites,
        (i: SiteSummary) => i.customer_id,
      );
      const newSitesListResponse = clientCustomers;
      for (const { oldObj, newObj } of toUpdate) {
        const currentProperties = await apiService.getCustomerProperties(oldObj.customer_id);
        await apiService.postCustomerProperties(oldObj.customer_id, {
          ...currentProperties,
          ...newObj.properties,
        });
        const idx = newSitesListResponse.sites.indexOf(oldObj);
        newSitesListResponse.sites[idx] = newObj;
      }

      return newSitesListResponse;
    },
  });

  return {
    customers: data,
    setCustomers: setData,
    state,
    ...rest,
  };
};

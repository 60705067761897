import apiServiceOld from './api-service-old';
import * as service from './generated/services.gen';

export const apiService = {
  ...service,
  old: apiServiceOld,
};

export const useApiService = () => apiService;

export type ApiService = typeof apiService;

/**
 * Normally to select a file you have to use a file input element.
 * In the case of a drag-drop-zone it would be nice to be able to
 * click on the zone to select a file.
 *
 * This component wraps a file input element as a clickable element.
 * It has no visual appearance itself, but can wrap other components
 */

import React, { useCallback, useRef } from 'react';

export interface FileInputProps {
  onChange: (files: FileList | null) => void;
  className?: string;
}

export const ClickForFileChooser: React.FC<FileInputProps> = ({
  onChange,
  children,
  className = '',
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  return (
    <div onClick={onClick} aria-hidden="true" className={className} style={{ cursor: 'pointer' }}>
      {children}
      <input
        ref={fileInputRef}
        type="file"
        multiple
        onChange={(e) => onChange(e.target.files)}
        className="d-none"
      />
    </div>
  );
};

import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'ap-southeast-2_oRHX9GBEK',
      userPoolClientId: '1pko4kjgrn0c9m7ct6ns8l1gtc',
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: 'carboncrop.auth.ap-southeast-2.amazoncognito.com',
          // URLs for social identity provider
          redirectSignIn: [`${window.location.origin}/login`],
          redirectSignOut: [`${window.location.origin}/login`],
          // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
          responseType: 'code',
          scopes: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'],
        },
      },
      mfa: {
        status: 'optional',
        totpEnabled: true,
      },
    },
  },
});

import { DragAndDropWithRegexProps } from '../customer/upload-download/MultiDragAndDrop';

export interface FileToUpload {
  size: number;
  name: string;
  contents: unknown;
  raw?: File;
}

export enum FileStateStatus {
  Ready,
  Error,
  FileDropped,
  Uploading,
  Uploaded,
}

export type FileState =
  | {
      status: FileStateStatus.Ready;
    }
  | {
      status: FileStateStatus.Error;
      error: string;
    }
  | {
      status: FileStateStatus.FileDropped;
      file: FileToUpload;
      matchingDragAndDrop: DragAndDropWithRegexProps;
    }
  | {
      status: FileStateStatus.Uploading;
    }
  | {
      status: FileStateStatus.Uploaded;
    };

/**
 * A small widget a bit like a button that a file can be dragged and dropped onto.
 * Doesn't ask for any sort of confirmation, just does it.
 */

import { faCheckCircle, faTimesCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { DragEvent, useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { ApiError } from '../apis/generated';
import { FileState, FileStateStatus } from '../models/Files';
import { ClickForFileChooser } from './ClickForFileChooser';
import { DragDropStyled } from './DragDropStyled';

export interface MiniDragAndDropProps {
  uploadFile: (file: File) => Promise<void>;
  className?: string;
  alreadyUploaded?: boolean;
}

export const MiniDragAndDrop: React.FC<MiniDragAndDropProps> = ({
  uploadFile,
  children,
  className,
  alreadyUploaded = false,
}) => {
  const [dragEntered, setDragEntered] = useState(0);
  const [fileState, setFileState] = useState<FileState>({ status: FileStateStatus.Ready });

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragEntered(dragEntered + 1);
  };
  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragEntered(dragEntered - 1);
  };

  const handleDragMove = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const doUpload = async (files: FileList | null) => {
    if (files === null) {
      return;
    }
    if (files.length !== 1) {
      setFileState({ status: FileStateStatus.Error, error: 'Only one file may be uploaded' });
      return;
    }
    const dataTransferFile = files[0];

    try {
      setFileState({
        status: FileStateStatus.Uploading,
      });
      await uploadFile(dataTransferFile);
      setFileState({
        status: FileStateStatus.Uploaded,
      });
    } catch (e) {
      if (e instanceof ApiError) {
        setFileState({
          status: FileStateStatus.Error,
          error: `${e.message} ${JSON.stringify(e.body)}`,
        });
      } else {
        setFileState({
          status: FileStateStatus.Error,
          error: `${e.message}`,
        });
      }
    }
  };

  const handleDrop = async (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragEntered(0);
    await doUpload(e.dataTransfer.files);
  };

  return (
    <ClickForFileChooser onChange={doUpload} className={className}>
      <DragDropStyled
        visualState={dragEntered > 0 ? 'dragging' : 'idle'}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragMove}
      >
        <div
          className="p-2 d-flex justify-content-between align-items-center gap-2"
          style={{ minHeight: '3.2em' }}
        >
          {fileState.status === FileStateStatus.Ready && !alreadyUploaded && (
            <FontAwesomeIcon size="lg" icon={faUpload} className="text-muted" />
          )}
          {fileState.status === FileStateStatus.Ready && alreadyUploaded && (
            <FontAwesomeIcon size="lg" icon={faCheckCircle} className="text-success" />
          )}
          {fileState.status === FileStateStatus.Uploading && <Spinner animation="border" />}
          {fileState.status === FileStateStatus.Error && (
            <FontAwesomeIcon size="lg" icon={faUpload} className="text-muted" />
          )}
          {fileState.status === FileStateStatus.Uploaded && (
            <FontAwesomeIcon size="lg" icon={faCheckCircle} className="text-success" />
          )}
          {children}
          <div />
        </div>

        {fileState.status === FileStateStatus.Error && (
          <Alert variant="danger" className="m-0 d-flex gap-3 p-2">
            <FontAwesomeIcon size="lg" icon={faTimesCircle} />

            {fileState.error}
          </Alert>
        )}
      </DragDropStyled>
    </ClickForFileChooser>
  );
};

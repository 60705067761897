import { Feature, featureCollection, FeatureCollection, Geometry } from '@turf/turf';
import { updateInArray } from '../../array_tools';
import { ContextState } from './ContextState';
import { UpdaterFunction } from '../remote-data/sources/Store';

/**
 * Selects the provided feature IDs
 */
export const selectFeatures = (
  state: ContextState,
  selectedFeatureIds: Array<string | number>,
): ContextState => {
  return {
    ...state,
    selectedFeatureIds: selectedFeatureIds,
  };
};

/**
 * Returns a feature from a feature collection given the
 * features ID. If the ID exists multiple times, the
 * first matching feature is returned
 *
 * @param collection Feature array to search through
 * @param featureId ID of the desired feature
 * @returns The feature or undefined if it doesn't exist
 */
export const getFeatureById = <G extends Geometry, P>(
  collection: FeatureCollection<G, P>,
  featureId: string | number | undefined,
): Feature<G, P> | undefined => {
  return collection.features.find((f) => f.id === featureId);
};

/**
 * Updates a feature in a feature collection based on it's
 * ID. If the feature doesn't exist no operation is performed
 *
 * @param collection
 * @param feature
 * @returns
 */
export const updateFeatureById = <G extends Geometry, P>(
  collection: FeatureCollection<G, P>,
  feature: Feature<G, P>,
): FeatureCollection<G, P> => {
  return featureCollection(updateInArray(collection.features, feature, (a, b) => a.id === b.id));
};

/**
 * Updates the properties in a feature in a feature collection based on it's
 * ID. If the feature doesn't exist no operation is performed
 */
export const updateFeaturePropertiesById = <G extends Geometry, P>(
  collection: FeatureCollection<G, P>,
  featureId: string | number | undefined,
  propertiesModifier: UpdaterFunction<P>,
): FeatureCollection<G, P> => {
  const oldFeature = getFeatureById<G, P>(collection, featureId);
  if (!oldFeature) {
    return collection;
  }
  const newFeature: Feature<G, P> = {
    ...oldFeature,
    properties: propertiesModifier(oldFeature.properties),
  };
  return featureCollection(updateInArray(collection.features, newFeature, (a, b) => a.id === b.id));
};

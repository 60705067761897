class Non200Error extends Error {
  constructor(public status: number, public body: string) {
    super(`Api returned: ${status} ${body}`);
  }
}

async function throwIfNotOk(response: Response) {
  if (!response.ok) {
    let niceBody: string;
    try {
      niceBody = await response.text();
    } catch (e) {
      niceBody = ``;
    }

    throw new Non200Error(response.status, niceBody);
  }
}

export async function get<T>(query: string, headers: Record<string, string> = {}): Promise<T> {
  const fetchResponse = await fetch(`${query}`, {
    method: 'GET',
    headers: headers,
  });
  await throwIfNotOk(fetchResponse);
  return fetchResponse.json();
}

export async function getBlob(query: string, headers: Record<string, string> = {}): Promise<Blob> {
  const fetchResponse = await fetch(`${query}`, {
    method: 'GET',
    headers: headers,
    redirect: 'follow',
  });
  await throwIfNotOk(fetchResponse);
  return fetchResponse.blob();
}

export async function postRaw<U>(
  query: string,
  body: BodyInit,
  headers: Record<string, string> = {},
): Promise<U> {
  const fetchResponse = await fetch(`${query}`, {
    method: 'POST',
    body: body,
    headers,
  });
  await throwIfNotOk(fetchResponse);
  return fetchResponse.json();
}

export async function post<T, U>(
  query: string,
  body: T,
  headers: Record<string, string> = {},
): Promise<U> {
  return postRaw(query, JSON.stringify(body), {
    ...headers,
    'Content-Type': 'application/json',
  });
}

export async function postThenBlob<T>(
  query: string,
  body: T,
  headers: Record<string, string> = {},
): Promise<Blob> {
  const fetchResponse = await fetch(`${query}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  });
  await throwIfNotOk(fetchResponse);
  return fetchResponse.blob();
}

export async function put<T, U>(
  query: string,
  body: T,
  headers: Record<string, string> = {},
): Promise<U> {
  const fetchResponse = await fetch(`${query}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  });
  await throwIfNotOk(fetchResponse);
  return fetchResponse.json();
}

export async function del<U>(query: string, headers: Record<string, string> = {}): Promise<U> {
  const fetchResponse = await fetch(`${query}`, {
    method: 'DELETE',
    headers,
  });
  await throwIfNotOk(fetchResponse);
  return fetchResponse.json();
}

export async function postFile<T>(
  query: string,
  headers: Record<string, string>,
  fileContents: string | Blob | File,
): Promise<T> {
  const formData = new FormData();
  formData.append('file', fileContents);
  const fetchResponse = await fetch(`${query}`, {
    method: 'POST',
    headers: headers,
    body: formData,
  });
  await throwIfNotOk(fetchResponse);
  return fetchResponse.json();
}

// Our colour palette is inspired by the colours of native birds.
// The primary pallette is split into the 3 key product divisions
// The native CCU Pallette is designed to grow and expand as we move to
// different markets.

import parse from 'parse-css-color';
import colors from './colors.module.scss';

export const {
  // Greys
  grey_10,
  grey_22,
  grey_30,
  grey_40,
  grey_50,
  grey_60,
  grey_70,
  grey_80,
  grey_90,
  grey_95,

  lime_25,
  lime_35,
  lime_45,
  lime_70,
  lime_85,

  pink_95,
  pink_90,
  pink_84,
  pink_70,
  pink_55,

  // Other colors
  carboncrop_holder,
  cc_lime,
  cc_pink,
  cc_offwhite,
  cc_charcoal,

  // Tokens
  nzu_orange,
  nzu_blue,
  nzu_blue_93,

  // CCU's
  ccu_aquamarine,
  ccu_rose,

  // Secondary Colors
  sec_fawn,
  sec_kakariki,
  sec_yellowhead,
  sec_tui,
  sec_birch,
  sec_parakeet,
  sec_kokako,
  sec_kiwi,
  sec_loro,
  sec_deep_bush,
} = colors;

export const category_colors_v2 = {
  existing_indigenous_ets_forest: colors.existing_indigenous_ets_forest_v2,
  future_native_planting: colors.future_native_planting_v2,
  existing_exotic_ets_forest: colors.existing_exotic_ets_forest_v2,
  future_exotic_planting: colors.future_exotic_planting_v2,
  potential_ets_forest: colors.potential_ets_forest_v2,
  scattered_forest: colors.scattered_forest_v2,
  pre_1990_forest: colors.pre_1990_forest_v2,
  pasture_no_forest: colors.pasture_no_forest_v2,
  native_carboncrop: colors.native_carboncrop_v2,
};

export const COLORS = colors;

export const rgbStringToRGB = (str: string): { r: number; g: number; b: number; a?: number } => {
  const res = parse(str);

  if (res && res.type === 'rgb') {
    return {
      r: res.values[0],
      g: res.values[1],
      b: res.values[2],
      a: res.alpha === 1 ? undefined : res.alpha,
    };
  }

  console.error('failed to parse color as rgb', str);
  // Fallback to bright red!
  return { r: 255, g: 0, b: 0 };
};

export const RGBToString = (rgb: { r: number; g: number; b: number; a?: number }): string => {
  const { r, g, b, a } = rgb;
  if (a !== undefined) {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

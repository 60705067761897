import { useApiService } from '../../apis/api-service';
import { useStore } from './sources/useStore';

export const useCompany = (company_id?: number) => {
  const apiService = useApiService();

  const { data, ...rest } = useStore({
    key: `/companies/${company_id}`,
    getFromServer: async () => {
      if (company_id === undefined) {
        return undefined;
      }
      return apiService.getCompany(company_id);
    },
  });

  return {
    company: data,
    ...rest,
  };
};

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { cc_charcoal } from '../theme/colors';

export interface BagelTopping {
  label: string;
  color: string;
  data: number;
}

export const transformBagelToppingIntoChartData = (
  toppings: BagelTopping[],
  hoverLabel: string,
  emptyLabel: string,
) => {
  return {
    labels:
      toppings.length > 0
        ? toppings.map((val) => {
            return val.label;
          })
        : [emptyLabel],
    datasets: [
      {
        label: hoverLabel,
        data:
          toppings.length > 0
            ? toppings.map((val) => {
                return Math.round(val.data);
              })
            : [0.0001],
        backgroundColor:
          toppings.length > 0
            ? toppings.map((val) => {
                return val.color;
              })
            : [cc_charcoal],
      },
    ],
  };
};

const BagelChart: React.FC<{
  hoverLabel: string;
  emptyLabel?: string;
  toppings: BagelTopping[];
}> = ({ hoverLabel, emptyLabel = 'No data', toppings }) => {
  return (
    <div style={{ width: '20em', height: '12em' }}>
      <Doughnut
        data={transformBagelToppingIntoChartData(toppings, hoverLabel, emptyLabel)}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              onClick: () => undefined,
              position: 'left',
              labels: {
                boxWidth: 10,
                boxHeight: 10,
                pointStyle: 'circle',
                usePointStyle: true,
                color: 'lightgrey',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default BagelChart;

import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import uuid from 'uuid';

import _ from 'lodash';
import { ProfileBubble } from '../customer/comments/ProfileBubble';
import { CompanyUser, useCompanyUsers } from './useCompanyUsers';

import { useUserMe } from '../contexts/remote-data/useUserMe';
import './AssigneeDropdown.scss';
import { useCurrentCompanyIdUltimate } from '../contexts/remote-data/useCurrentCompanyIdUltimate';

export const EditAssigneeDropdown: React.FC<{
  assignee?: number;
  setAssignee: (assignee: number | undefined) => void;
  display?: 'bubble' | 'dropdown';
}> = ({ assignee, setAssignee, display = 'bubble' }) => {
  const [searchString, setSearchString] = React.useState<string>('');

  const companyId = useCurrentCompanyIdUltimate();

  const { users } = useCompanyUsers(companyId);

  const filterFun = (u: CompanyUser | { user_id: undefined; email: undefined }) =>
    searchString.length > 0 ? u.email?.toLowerCase().includes(searchString.toLowerCase()) : true;

  const userListFiltered = users?.filter(filterFun) ?? [];

  const { userMe } = useUserMe();

  const isDropdown = display === 'dropdown';

  return (
    <Dropdown className={`${isDropdown ? 'w-100' : ''}`}>
      <Dropdown.Toggle
        id="dropdown-basic"
        variant={`${isDropdown && 'outline-dark'}`}
        className={`text-light ${
          isDropdown ? 'border border-light gap-2 w-100' : 'border-0 hidden-dropdown '
        }`}
      >
        <ProfileBubble
          email={users?.find((user) => user.user_id === assignee)?.email}
          size={isDropdown ? 'xs' : 'sm'}
          hoverShadow={!isDropdown}
        />
        {isDropdown && (
          <span className="assigneeLabel">
            {users?.find((user) => user.user_id === assignee)?.email || 'Unassigned'}
          </span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ zIndex: 1050 }}>
        <div className="px-3 pt-2 pb-3">
          <Form.Control
            onBlur={() => setTimeout(() => setSearchString(''), 1000)}
            type="text"
            className="w-100 rounded p-1"
            autoFocus
            placeholder="Search"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
        </div>

        <div style={{ width: '25em', height: '25em' }} className="overflow-y-scroll">
          {[
            ...[
              assignee !== userMe?.user_id && users?.find((user) => user.user_id === assignee),
              users?.find((user) => user.user_id === userMe?.user_id),
              {
                user_id: undefined,
                email: undefined,
              },
            ].filter((u) => u !== false && u !== undefined && filterFun(u)),
            ..._.sortBy(
              [
                ...userListFiltered.filter(
                  (u) => u.user_id !== assignee && u.user_id !== userMe?.user_id,
                ),
              ],
              (u) =>
                'z'.repeat(u.email.toLowerCase().indexOf(searchString.toLowerCase())) + u.email,
            ),
          ].map((user) => {
            return (
              user && (
                <Dropdown.Item
                  key={uuid.v4()}
                  onClick={() => {
                    setAssignee(user.user_id);
                  }}
                  className={`d-flex align-items-center w-100 overflow-hidden gap-2 ${
                    user.user_id === assignee ? 'bg-primary' : ''
                  }`}
                >
                  <ProfileBubble email={user.email} />
                  {user.email ?? 'Unassigned'}
                </Dropdown.Item>
              )
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

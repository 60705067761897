import { useParams } from 'react-router';
import { useCustomerCompanyCarbonManager } from './useCustomerCompanyCarbonManager';

export const useCurrentCompanyIdUltimate = (): number | undefined => {
  const { companyId } = useParams();
  const { customerId } = useParams();
  const { carbonManager } = useCustomerCompanyCarbonManager(Number(customerId));

  if (companyId) {
    return Number(companyId);
  }
  if (customerId) {
    return carbonManager?.company_id;
  }
  throw new Error(
    'The useCurrentCompanyId hook can only be used in a page that contains a companyId or customerId path parameter',
  );
};

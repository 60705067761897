import React, { ReactNode } from 'react';
/**
 * Puts a circle around something.
 * Makes sure that the contents is centered
 */

export const Circled: React.FC<{ className: string; size: string; borderWidth?: number }> = ({
  children,
  className,
  size,
  borderWidth = 3,
}) => {
  return (
    <div
      className={`border border-${borderWidth} rounded-circle d-flex justify-content-center align-items-center ${className}`}
      style={{ width: size, height: size }}
    >
      <div>{children}</div>
    </div>
  );
};

export const TitledCircle: React.FC<{ className: string; size: string; title: ReactNode }> = ({
  children,
  className,
  size,
  title,
}) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <Circled size={size} className={className}>
        {children}
      </Circled>
      {title}
    </div>
  );
};

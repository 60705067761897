import { Authenticator } from '@aws-amplify/ui-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import GlobalErrorHandler from './logger/GlobalErrorHandler';
import reportWebVitals from './reportWebVitals';
import './theme/carboncrop.scss';
import { getRunningEnv } from './env_tools';

// Initialize Sentry as early as possible
Sentry.init({
  dsn: 'https://38c10e9053169cef4c763924d3ec8862@o4507898187939840.ingest.us.sentry.io/4507898196590592',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: getRunningEnv(),
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/test-app\.carboncrop\.nz\/api/,
    /^https:\/\/app\.carboncrop\.nz\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// This is deprecated, but changing this to createRoot caused white flashes when showing tooltips.
// eslint-disable-next-line
ReactDOM.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <BrowserRouter>
        <GlobalErrorHandler>
          <App />
        </GlobalErrorHandler>
      </BrowserRouter>
    </Authenticator.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

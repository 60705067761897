// https://www.w3.org/TR/trace-context-1/#relationship-between-the-headers

function randomHexBytes(length: number): string {
  const bytes = new Uint8Array(length);
  window.crypto.getRandomValues(bytes);
  return Array.from(bytes, (byte) => byte.toString(16).padStart(2, '0')).join('');
}

export class Tracer {
  private version: string;
  private traceId: string;
  private parentId: string | undefined;
  private flags: string;

  private traceState: { [key: string]: string };

  constructor() {
    this.version = '00';
    this.traceId = randomHexBytes(16);
    this.parentId = randomHexBytes(8);
    this.flags = '01';
    this.traceState = {};
  }

  public getTraceState(): string {
    const states: string[] = [];
    Object.entries(this.traceState).forEach(([key, value]) => {
      states.push(`${key}=${value}`);
    });
    const traceState = states.join(',');
    return traceState;
  }

  public getTraceParent(): string {
    const header = `${this.version}-${this.traceId}-${this.parentId}-${this.flags}`;
    return header;
  }

  public setState(states: { [key: string]: string }): void {
    this.traceState = states;
  }
}

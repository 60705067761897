/* eslint-disable camelcase */
import React from 'react';
import '../theme/carboncrop.scss';

import { Link } from 'react-router-dom';
import { cc_offwhite } from '../theme/colors';

export interface CardLinkParams {
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const CardLink: React.FC<CardLinkParams> = ({ href, onClick, children }) => {
  return (
    <Link
      className="
        d-flex
        p-1
        align-items-center
        gap-2
        rounded
        text-decoration-none
        bg-hover-grey-95
      "
      to={href ?? ''}
      onClick={onClick}
      style={{ backgroundColor: cc_offwhite }}
    >
      {children}
    </Link>
  );
};

export default CardLink;

import React from 'react';
import { Button, Image } from 'react-bootstrap';
import Fantail from '../theme/images/branding/birds/fantail.png';
import { ErrorPageContainer } from './Container';

export const NotFound = () => {
  return (
    <ErrorPageContainer>
      <h1>404</h1>
      <h2>Whoops! You’ve Strayed Off the Forest Path!</h2>
      <Image src={Fantail} style={{ width: '160px' }} />

      <p>What you were looking for doesn’t currently exist at this address.</p>
      <Button variant="primary" href="/">
        Home
      </Button>
    </ErrorPageContainer>
  );
};

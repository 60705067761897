import React from 'react';
import { Bar } from 'react-chartjs-2';
import { cc_offwhite } from '../../theme/colors';

export interface BarTopping {
  label: string;
  color: string;
  data: number[];
}

const transformBarToppingIntoChartData = (
  emptyLabel: string,
  toppings: BarTopping[],
  xLabels: string[],
) => {
  return {
    labels: xLabels,
    datasets:
      toppings.length > 0
        ? toppings.map((val) => {
            return {
              label: val.label,
              data: val.data.map((v) => Math.round(v)),
              backgroundColor: val.color,
            };
          })
        : [{ label: emptyLabel, data: [], backgroundColor: 'rgba(0, 0, 0, 0)' }],
  };
};

const ChocolateBar: React.FC<{
  emptyLabel?: string;
  xLabels: string[];
  toppings: BarTopping[];
}> = ({ emptyLabel = 'No data', toppings, xLabels }) => {
  return (
    <div style={{ width: '23em', height: '12em' }}>
      <Bar
        type="bar"
        data={transformBarToppingIntoChartData(emptyLabel, toppings, xLabels)}
        options={{
          plugins: {
            legend: {
              display: true,
              position: 'left',
              labels: {
                boxWidth: 8,
                boxHeight: 10,
                pointStyle: 'circle',
                usePointStyle: true,
                color: cc_offwhite,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,

          elements: {
            bar: {
              borderWidth: 2,
              borderSkipped: 'start',
              borderColor: cc_offwhite,
            },
          },

          scales: {
            y: {
              stacked: true,
              ticks: {
                /* eslint-disable  func-names */
                // @ts-ignore
                callback: function (value: number) {
                  if (value >= 10000) {
                    return `${(value / 1000).toFixed(0)}k`;
                  }
                  if (value >= 1000) {
                    return `${(value / 1000).toFixed(1)}k`;
                  }
                  return toppings.length > 0
                    ? Number(value.toString()).toLocaleString('en-US')
                    : '';
                },
                /* eslint-enable  func-names */
                color: cc_offwhite,
              },
              grid: {
                color: 'rgba(0, 0, 0, 0)',
              },
              border: {
                color: cc_offwhite,
              },
            },
            x: {
              stacked: true,
              ticks: {
                display: true,
                color: cc_offwhite,
                callback: (tickValue, index) => `’${xLabels[index].substring(2, 4)}`,
              },
              grid: {
                color: 'rgba(0, 0, 0, 0)',
              },
              border: {
                color: cc_offwhite,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ChocolateBar;

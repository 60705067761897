import { useApiService } from '../../apis/api-service';
import { useStore } from './sources/useStore';

export const useCompanyProperties = (company_id?: number) => {
  const apiService = useApiService();

  const { data, setData, ...rest } = useStore({
    key: `/companies/${company_id}/properties`,
    getFromServer: async () => {
      if (company_id === undefined) {
        return undefined;
      }
      return apiService.getCompanyProperties(company_id);
    },
    saveToServer: async (_serverData, clientData) => {
      if (company_id === undefined || clientData === undefined) {
        return undefined;
      }
      await apiService.putCompanyProperties(company_id, clientData);
      return clientData;
    },
  });

  return {
    companyProperties: data,
    setCompanyProperties: setData,
    ...rest,
  };
};

import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatDate, formatNum } from '../formatters/formatters';

import { SiteSummary } from '../apis/generated';
import stylesTask from './TaskEntry.module.scss';
import { EditAssigneeDropdown } from './EditAssigneeDropdown';

const getFilteredMeasures = (site: SiteSummary, filterDimensionsKeys?: string[]) => {
  return filterDimensionsKeys === undefined
    ? Object.values(site.measures)
    : filterDimensionsKeys.map((key) => site.measures[key]).filter((m) => m !== undefined);
};

const CustomerFilteredEntry: React.FC<{
  value?: number | null;
  totalValue?: number | null;
}> = ({ value, totalValue }) => {
  return totalValue === value || totalValue === undefined || totalValue === null ? (
    <div className="fs-5">{formatNum(value)}</div>
  ) : (
    <div className="fs-5 d-flex align-items-sm-baseline gap-1">
      {`${formatNum(value, 0)}`}{' '}
      <small className="text-muted">{`of ${formatNum(totalValue, 0)}`}</small>
    </div>
  );
};

// Calculate in store will slow down the initial display
export const calculateForestArea = (
  site: SiteSummary,
  filterDimensionsKeys: string[] | undefined = undefined,
): number =>
  getFilteredMeasures(site, filterDimensionsKeys).reduce((acc, current) => acc + current.aea, 0);

export const calculateSequestration = (
  site: SiteSummary,
  year_idx: number,
  filterDimensionsKeys: string[] | undefined = undefined,
): number =>
  getFilteredMeasures(site, filterDimensionsKeys).reduce(
    (acc, current) => (current.seq.length > 0 ? acc + current.seq[year_idx] : acc),
    0,
  );

const CustomerEntryV2: React.FC<{
  customer: SiteSummary;
  filterDimensionsKeys: string[] | undefined;
  setCustomer: (site: SiteSummary) => void;
}> = ({ customer, filterDimensionsKeys, setCustomer: updateSiteProperties }) => {
  const onClick = useCallback(() => {
    window.open(`/customer/${customer.customer_id}`, '_blank');
  }, [customer.customer_id]);

  return (
    <Row className={` m-0 bg-hover-grey-95 cursor-pointer ${stylesTask.task_row}`}>
      <Col className=" py-3 d-flex" lg={3} onClick={onClick}>
        <div className="align-content-center">
          {customer.properties.name ? customer.properties.name : `CCP${customer.customer_id}`}
        </div>
      </Col>
      <Col className="py-3" lg={2} onClick={onClick}>
        <div className="fs-5">
          <CustomerFilteredEntry value={customer.site_area_m2 && customer.site_area_m2 / 1e4} />
        </div>
        <div className="text-muted">
          <small>Hectares</small>
        </div>
      </Col>
      <Col className="py-3" lg={2} onClick={onClick}>
        <div className="fs-5">
          <CustomerFilteredEntry
            value={calculateForestArea(customer, filterDimensionsKeys)}
            totalValue={calculateForestArea(customer)}
          />
        </div>
        <div className="text-muted">
          <small>Hectares</small>
        </div>
      </Col>
      <Col className="py-3" lg={2} onClick={onClick}>
        <div>
          <div className="fs-5">
            <CustomerFilteredEntry
              value={calculateSequestration(customer, 0, filterDimensionsKeys)}
              totalValue={calculateSequestration(customer, 0)}
            />
          </div>
          <div className="text-muted">
            <small>
              tonnes of CO<sub>2</sub>
            </small>
          </div>
        </div>
      </Col>
      <Col className="py-3 d-flex" lg={2} onClick={onClick}>
        <div className="align-content-center">{formatDate(customer?.edit_time)}</div>
      </Col>
      <Col className="py-3 d-flex align-items-center gap-2" lg={1}>
        <EditAssigneeDropdown
          setAssignee={async (ass) => {
            updateSiteProperties({
              ...customer,
              properties: {
                ...customer.properties,
                assignee: ass,
              },
            });
          }}
          assignee={customer.properties.assignee}
        />
      </Col>
    </Row>
  );
};

export default CustomerEntryV2;

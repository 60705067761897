import { useMemo } from 'react';
import mixpanel from 'mixpanel-browser';
import { OpenAPI, UserInfoResponse } from '../../apis/generated';
import { useStore } from './sources/useStore';
import { useApiService } from '../../apis/api-service';

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Fills a user's name from their email address
 * This is done because we don't currently have the ability
 * for people to set their own names and when signing in our portal
 * you only provide an email address.
 *
 * This should be replaced when whe create the ability for a user to
 * set their own name.
 */
export const patchUserNameFromEmails = (user: UserInfoResponse): UserInfoResponse => {
  if (user.email && !user.first_name) {
    const [first_name, last_name] = user.email.split('@')[0].split('.');
    return {
      ...user,
      first_name: capitalizeFirstLetter(first_name ?? ''),
      last_name: capitalizeFirstLetter(last_name ?? ''),
    };
  }
  return user;
};

export const useUserMe = () => {
  const apiService = useApiService();

  const { data, state } = useStore({
    key: `/users/me`,
    getFromServer: async () => {
      const userMe = OpenAPI.TOKEN ? await apiService.getUsersMe() : undefined;
      if (userMe) {
        mixpanel.identify(String(userMe.user_id));
        mixpanel.people.set({
          $email: userMe.email,
          $name: `${userMe.first_name} ${userMe.last_name}`,
        });
      }
      return userMe;
    },
    saveToServer: async () => {
      throw new Error('Not implemented');
    },
  });

  const patchData = useMemo(
    () => (data === undefined ? undefined : patchUserNameFromEmails(data)),
    [data],
  );

  return {
    userMe: patchData,
    state,
  };
};

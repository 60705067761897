import { EstablishmentMethodType, establishment_methods } from '@nai/ets-polygons';
import { convertArea } from '@turf/helpers';
import _ from 'lodash';
import { reportCategories, ReportCategoryType } from '../models/Blocks';
import { TitleWithOwner } from '../apis/generated';

export const formatNum = (
  num: number | undefined | null,
  precise: number | undefined = 0,
): string => {
  if (!num) {
    return '?';
  }
  if (num === 0) {
    return '0';
  }
  return num.toLocaleString('en-US', {
    minimumFractionDigits: precise,
    maximumFractionDigits: precise,
  });
};

// Format finance amount with rounded precision.
// For example, with precision 3:
// 1,234,567 -> 1,230,000
//   123,456 ->   123,000
//    12,345 ->    12,300
//     1,234 ->     1,230
//       123 ->       123
//        12 ->        12
export const formatFinanceSignificantFigures = (
  num: number | undefined,
  precise: number,
): string => {
  if (num === undefined) {
    return '$?';
  }
  if (num === 0) {
    return '$0';
  }

  return `$${parseFloat(num.toPrecision(precise)).toLocaleString('en-US')}`;
};

// Replace last string.
export const replaceLast = (original: string, search: string, replace: string): string => {
  return original.replace(new RegExp(`${search}([^${search}]*)$`), `${replace}$1`);
};

export function formatArea(areaM2: number, numDecimalPlaces = 0, includeUnit = true): string {
  const areaHa = convertArea(areaM2, 'meters', 'hectares');
  return `${areaHa.toLocaleString('en-US', {
    minimumFractionDigits: numDecimalPlaces,
    maximumFractionDigits: numDecimalPlaces,
  })}${includeUnit ? ' ha' : ''}`;
}

export function formatTotalArea(areaHa: number): string {
  return `${areaHa.toLocaleString('en-US')} ha`;
}

export function getTotalAreaM2(parcels: TitleWithOwner[]): number {
  return parcels.reduce((acc, p) => acc + p.total_area_m2, 0);
}

export function getTotalAreaHa(parcels: TitleWithOwner[]): number {
  const areaHa = convertArea(getTotalAreaM2(parcels), 'meters', 'hectares');
  if (areaHa < 1.0) {
    return Number(areaHa.toFixed(2));
  }
  if (areaHa < 10.0) {
    return Number(areaHa.toFixed(1));
  }
  return Number(areaHa.toFixed(0));
}

// Formats an area with separators for each thousands column
export function formatWithThousandsSeparators(num: number, numDecimalPlaces = 0): string {
  return num.toLocaleString('en-US', {
    minimumFractionDigits: numDecimalPlaces,
    maximumFractionDigits: numDecimalPlaces,
  });
}

// Formats a proportion (eg 0.5) to a percentage (eg "50%")
export function formatProportionAsPercent(proportion: number, dp = 0): string {
  return `${(proportion * 100).toFixed(dp)}%`;
}

export const formatValueToMoneyString = (value: number): string => {
  return `$${Math.round(value).toLocaleString()}`;
};

export const formatStringToTitleCase = (str: string): string => {
  const str_trimmmed = _.trim(str);
  if (str_trimmmed === '') {
    return '';
  }
  return str_trimmmed
    .split(' ')
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');
};

export const formatBytesAsFileSize = (bytes: number): string => {
  return `${Math.round(bytes / 1e3) / 1e3} MB`;
};

export const formatDateToYear = (date: Date): string => {
  return date.getFullYear().toString();
};

export const numberToHumanString = (num: number): string => {
  const lookup = new Map([
    [0, 'zero'],
    [1, 'one'],
    [2, 'two'],
    [3, 'three'],
    [4, 'four'],
    [5, 'five'],
    [6, 'six'],
    [7, 'seven'],
    [8, 'eight'],
    [9, 'nine'],
    [10, 'ten'],
    [11, 'eleven'],
    [12, 'twelve'],
    [13, 'thirteen'],
    [14, 'fourteen'],
    [15, 'fifteen'],
    [16, 'sixteen'],
    [17, 'seventeen'],
    [18, 'eighteen'],
    [19, 'nineteen'],
  ]);
  const known = lookup.get(num);
  return known ?? num.toString();
};

/**
 * Converts from an establishment method (Eg "natural_regeneration") into a
 * human-presentable string "Natural Regeneration". If the method is not a known one,
 * it will return undefined
 *
 * @param method Normally one of EstablishmentMethodType
 * @returns Human Presentable name for this establishment method as a string, or undefined
 */
export const establishmentMethodToHumanString = (
  method: EstablishmentMethodType | undefined,
): string | undefined => {
  return establishment_methods.find((opt) => opt.key === method)?.display_name;
};

/**
 * Converts from the internal representation of a report category (Eg "potential_ets_forest")
 * into a human-presentable string "Potential Forest". If the category is not a known one,
 * it will return undefined
 * @param ets Normally one of reportCategories
 * @returns Human Presentable name for this report category as a string, or undefined
 */
export const reportCategoryToHumanString = (
  category: ReportCategoryType | string | undefined,
): string | undefined => {
  if (category === undefined) {
    return undefined;
  }
  const priDict = Object.assign({}, ...reportCategories.map((opt) => ({ [opt.val]: opt.text })));
  return priDict[category];
};

export const formatEtsDistrict = (district: string): string | undefined => {
  const DISTRICT_LOOKUP: { [district: string]: string | undefined } = {
    AUCKLAND: 'Auckland',
    'WAIKATO/TAUPO': 'Waikato/Taupo',
    'BAY OF PLENTY': 'Bay of Plenty',
    GISBORNE: 'Gisborne',
    'HAWKES BAY/SOUTHERN NORTH ISLAND': 'Hawkes Bay/Southern North Island',
    'NELSON/MARLBOROUGH': 'Nelson/Marlborough',
    'CANTERBURY/WEST COAST': 'Canterbury/West Coast',
    OTAGO: 'Otago',
    SOUTHLAND: 'Southland',
  };

  return DISTRICT_LOOKUP[district];
};

/**
 * Formats a sequence of numbers with hyphens and commas. Eg:
 * [1,2,3,4,5] => '1-5'
 * [1,2,3,5] => '1-3, 5'
 */
export const formatNumberRange = (numbers: number[]): string => {
  let outstring = '';
  let start: number = numbers[0];
  let end: number = numbers[0];
  numbers.forEach((number) => {
    if (number === end + 1 || number === end) {
      end = number;
    } else {
      if (start === end) {
        outstring += `, ${end}`;
      } else {
        outstring += `, ${start}-${end}`;
      }
      start = number;
      end = number;
    }
  });
  if (start === end) {
    outstring += `, ${end}`;
  } else {
    outstring += `, ${start}-${end}`;
  }
  return outstring.substring(2);
};

export const formatDate = (isoDate: string | undefined): string => {
  if (!isoDate) {
    return '';
  }
  return new Date(isoDate).toLocaleDateString('en-UK', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

/**
 * Format ISO date string to readable format.
 *
 * Eg: 15 Dec 2023, 02:47 pm
 */
export const formatDateAndTime = (isoDate: string | undefined): string => {
  if (!isoDate) {
    return '';
  }

  return new Date(isoDate).toLocaleString('en-NZ', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
  });
};

import React from 'react';
/*
 This file is pulled into the initial bundle so should be as small as
 possible. Additionally it should mirror the contents of the "root" div in the
 index.html for a smooth loading experience.
*/

export const Loading: React.FC<{ stage: number }> = ({ stage }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontFamily: 'sans-serif',
        height: '100vh',
      }}
    >
      <img src="/images/saddleback.svg" width="200px" alt="AI generated picture of a bird" />
      Loading{'.'.repeat(stage)}
    </div>
  );
};

import { useApiService } from '../../apis/api-service';
import { useStore } from './sources/useStore';

export const useUserMeCompanies = () => {
  const apiService = useApiService();

  const { data, state } = useStore({
    key: `/users/me/companies`,
    getFromServer: async () => apiService.getUsersMeCompanies(),
  });

  return {
    userMeCompanies: data,
    state,
  };
};

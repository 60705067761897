// A page used to test various errors. Use this to check logging and error reporting.
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

export const ErrorTester: React.FC = () => {
  const [componentError, setComponentError] = useState(false);
  const [useEffectError, setUseEffectError] = useState(false);

  if (componentError) {
    throw new Error('Error in component');
  }

  useEffect(() => {
    if (useEffectError) {
      throw new Error('Error in useEffect');
    }
  }, [useEffectError]);

  return (
    <div className="d-flex flex-column gap-2 align-items-center">
      <h1>Let’s create some errors</h1>
      <Button
        onClick={() => {
          throw new Error('Error in event handler');
        }}
      >
        Create an error inside the onClick handler
      </Button>
      <Button
        onClick={async () => {
          await new Promise((resolve) => {
            setTimeout(resolve, 20);
          });
          throw new Error('Error in async event handler');
        }}
      >
        Create an async error inside the onClick handler
      </Button>
      <Button onClick={() => setComponentError(true)}>Create a component render error</Button>
      <Button onClick={() => setUseEffectError(true)}>Create an error inside a useEffect</Button>
    </div>
  );
};

import { FeatureCollection, MultiPolygon, Polygon } from '@turf/turf';
import { stringify as qs } from 'query-string';
import { CtrFeature } from '../models/CarbonCropUnits';
import { Address, GetCustomersOverviewResponse } from '../models/Customer';
import {
  GetHubspotDealResponse,
  GetHubspotOwnerResponse,
  HubspotDealProperties,
} from '../models/Hubspot';
import { Invoice, InvoiceNZUsIssued, Payment } from '../models/Invoice';
import { GetLayerResponse, GetLayerVersionsResponse } from '../models/Layers';
import {
  GetRequestedPhotoPointsResponse,
  GetTakenPhotoPointsResponse,
  UploadRequestedPhotoPointsRequest,
  UploadRequestedPhotoPointsResponse,
} from '../models/PhotoPoints';
import { PostPhotosTakenResponse } from '../models/PhotosTaken';
import { SegmentsFeatureCollection, ShapefileSchemaType } from '../models/Segments';
import { OpenAPI, PaymentStatus } from './generated';
import { del, get, getBlob, post, postFile, postRaw, postThenBlob, put } from './request-utils';
import { Tracer } from './tracer';
import { BoundaryFeatureCollection } from '../contexts/remote-data/useBoundary';

interface GetBoundariesResult {
  boundaries: {
    customer_id: number;
    boundary: BoundaryFeatureCollection;
  }[];
}

// Do not add to this class anymore.
// Instead, run `npm run generate-api` to generate a new API service.
export class ApiServiceOld {
  private tracer: Tracer;

  constructor(private baseUrl: string) {
    this.baseUrl = baseUrl;
    this.tracer = new Tracer();
  }

  async postHubspotDealProperties(
    customerId: number | string,
    properties: HubspotDealProperties,
  ): Promise<GetHubspotDealResponse> {
    return this.post(`/v1/hubspot/deals/${customerId}/properties`, properties);
  }

  async getHubspotDeal(customerId: number | string): Promise<GetHubspotDealResponse[] | null> {
    return this.get(`/v1/hubspot/deals/${customerId}`);
  }

  async getHubspotOwner(ownerId: string): Promise<GetHubspotOwnerResponse> {
    return this.get(`/v1/hubspot/owners/${ownerId}`);
  }

  async getCustomerBillingAddress(customerId: number | string): Promise<Address> {
    return this.get(`/v1/customers/${customerId}/billing_address`);
  }

  async postRequestedPhotoPoints(
    customerId: number | string,
    points: UploadRequestedPhotoPointsRequest,
  ): Promise<UploadRequestedPhotoPointsResponse> {
    return this.post(`/v1/customers/${customerId}/photos/requested_points`, points);
  }

  async getRequestedPhotoPoints(
    customerId: number | string,
  ): Promise<GetRequestedPhotoPointsResponse> {
    return this.get(`/v1/customers/${customerId}/photos/requested_points`);
  }

  async getCustomersPhotosTakenPoints(
    customerId: number | string,
  ): Promise<GetTakenPhotoPointsResponse> {
    return this.get(`/v1/customers/${customerId}/photos/taken_points`);
  }

  async getCustomersOverview(): Promise<GetCustomersOverviewResponse> {
    return this.get(`/v1/customers/overview`);
  }

  async getCustomerBoundaries(
    customerIds: number[],
    dissolve = false,
  ): Promise<GetBoundariesResult> {
    if (customerIds.length > 60) {
      console.error('Max query length likely to be exceeded while requesting customer boundaries');
    }
    const idString = customerIds.map((id) => `project_ids=${id}&customer_id=${id}`);
    return this.get(`/v1/customers/boundaries?dissolve=${dissolve}&${idString.join('&')}`);
  }

  async postCustomerTitle(customerId: number, titles: string[]): Promise<void> {
    return this.post(`/v1/customers/${customerId}/titles`, titles);
  }

  async postCustomerShapefile(
    customerId: number,
    body: {
      geometry: FeatureCollection<Polygon | MultiPolygon>;
      shapefile_schema?: ShapefileSchemaType;
      shapefile_name?: string;
    },
  ): Promise<Blob> {
    return this.postThenBlob(`/v2/customers/${customerId}/shapefile`, body);
  }

  async getCtrShapefile(): Promise<Blob> {
    return this.getBlob(`/v1/ctr/shapefile`);
  }

  async getCustomerInvoices(customerId: number): Promise<Invoice[]> {
    return this.get(`/v1/customers/${customerId}/invoices`);
  }

  async postCustomerInvoice(customerId: number, invoice: Invoice): Promise<Invoice> {
    return this.post(`/v1/customers/${customerId}/invoices`, invoice);
  }

  async putCustomerInvoicePropertiesNzusIssued(
    customerId: number,
    invoiceId: number,
    nzusIssued: InvoiceNZUsIssued,
  ): Promise<{ invoice_id: number }> {
    return this.put(`/v1/customers/${customerId}/invoices/${invoiceId}/properties/nzus_issued`, {
      nzus_issued: nzusIssued,
    });
  }

  async putCustomerInvoicePaymentStatus(
    customerId: number,
    invoiceId: number,
    paymentId: number,
    status: PaymentStatus,
  ) {
    return this.put(
      `/v1/customers/${customerId}/invoices/${invoiceId}/payments/${paymentId}/status`,
      { status: status },
    );
  }

  async getCustomerInvoicePayments(customerId: number, invoiceId: number): Promise<Payment[]> {
    return this.get(`/v1/customers/${customerId}/invoices/${invoiceId}/payments`);
  }

  async getCustomerKMLFile(customerId: number): Promise<Blob> {
    return this.getBlob(`/v1/customers/${customerId}/kml_file`);
  }

  async postSegmentsEtsRegistration(customerId: number, segments: SegmentsFeatureCollection) {
    await this.post(`/v1/customers/${customerId}/segments/ets/registration`, segments);
  }

  async postCustomerPhotosTaken(
    customerId: number,
    files: File[],
  ): Promise<PostPhotosTakenResponse> {
    const body = new FormData();
    files.forEach((f) => body.append('img_files', f));
    return this.postRaw(`/v1/customers/${customerId}/photos/taken`, body);
  }

  async getCustomerPhotosTaken(
    customerId: number,
    fileId: string,
    size?: 'thumbnail',
  ): Promise<Blob> {
    return this.getBlob(`/v1/customers/${customerId}/photos/taken/${fileId}?size=${size ?? ''}`);
  }

  async deletePhoto(customerId: number, fileId: string): Promise<void> {
    await this.delete(`/v1/customers/${customerId}/photos/taken/${fileId}`);
  }

  async getCustomerLayerById(
    customerId: number,
    layerId: number,
    versionId?: number,
  ): Promise<GetLayerResponse> {
    return this.get(
      `/v1/customers/${customerId}/layers/${layerId}?${qs({ version_id: versionId })}`,
    );
  }

  async deleteCustomerLayerById(customerId: number, layerId: number): Promise<void> {
    return this.delete(`/v1/customers/${customerId}/layers/${layerId}`);
  }

  async getCustomerLayerByIdVersions(
    customerId: number,
    layerId: number,
  ): Promise<GetLayerVersionsResponse> {
    return this.get(`/v1/customers/${customerId}/layers/${layerId}/versions`);
  }

  async getCtrDetails(ctrId: number): Promise<CtrFeature> {
    return this.get(`/v1/ctr/${ctrId}`);
  }

  async postCustomersGdrive(
    customerId: number,
    internalPath: string,
    fileContents: string | Blob,
  ): Promise<{ id: string }> {
    const url = `/v1/customers/${customerId}/gdrive/${internalPath}`;
    return this.postFile(url, fileContents);
  }

  public async get<T>(query: string): Promise<T> {
    return get(`${this.baseUrl}${query}`, await this.getHeader());
  }

  public async getBlob(query: string): Promise<Blob> {
    return getBlob(`${this.baseUrl}${query}`, await this.getHeader());
  }

  public async postThenBlob<T>(query: string, body: T): Promise<Blob> {
    return postThenBlob(`${this.baseUrl}${query}`, body, await this.getHeader());
  }

  public async post<T, U>(query: string, body: T): Promise<U> {
    return post(`${this.baseUrl}${query}`, body, await this.getHeader());
  }

  public async put<T, U>(query: string, body: T): Promise<U> {
    return put(`${this.baseUrl}${query}`, body, await this.getHeader());
  }

  public async postRaw<U>(query: string, body: BodyInit): Promise<U> {
    return postRaw(`${this.baseUrl}${query}`, body, await this.getHeader());
  }

  public async postFile<T>(query: string, fileContents: string | Blob): Promise<T> {
    return postFile(`${this.baseUrl}${query}`, await this.getHeader(), fileContents);
  }

  public async delete<U>(query: string): Promise<U> {
    return del(`${this.baseUrl}${query}`, await this.getHeader());
  }

  public async getHeader(): Promise<{ [key: string]: string }> {
    const apiRequestOptions = {} as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    const token =
      typeof OpenAPI.TOKEN === 'function' ? await OpenAPI.TOKEN(apiRequestOptions) : OpenAPI.TOKEN;

    return {
      Authorization: `Bearer ${token}`,
      traceparent: this.tracer.getTraceParent(),
      tracestate: this.tracer.getTraceState(),
    };
  }
}

const apiService = new ApiServiceOld('/api');

export default apiService;
